import React from 'react';

function ShowPdf({ pdfUrl, className }) {
    return (
            <object data={pdfUrl} type="application/pdf" className={className} >
                <p>PDF cannot be displayed.</p>
            </object>
    );
}

export default ShowPdf;
