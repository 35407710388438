import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetDriveFoldersQuery } from "../../redux/juriiDriveApis";
import UploadData from "../../components/shared/upload-data/UploadData";
import { setDragEnter, resetDragState } from "../../redux/DropZoneReducer";
import ShowFile from "../../components/shared/modals/ShowFile";
import { BreadCrumb } from "../../components/common/BreadCrumb";
import { Config } from "../../constants/Index";
import { selectItemTypeModel } from "../../redux/DriveFilterReducer";
import { EmptyLoader, SimpleLoader } from "../../components/loader/Loaders";
import { resetBreadcrumbs } from "../../redux/BreadCrumbReducer";
import useGetPathUrl from "../../lib/useGetPathUrl";
import CreateDropDown from "../../components/shared/dropDowns/CreateDropDown";
import useOutsideClick from "../../lib/useOutsideClick";
import useSelectAllItems from "../../lib/useSelectAllItems";
import { SearchIcon } from "../../icons/generalIcons/Icons";
import DZBtn from "../../components/shared/buttons/DZBtn";
import { useSearchManager } from "../../lib/customHooks/useSearchManager";
import ViewFolders from "./ViewFolders";
import ViewFiles from "./ViewFiles";

const DriveHomes = React.memo(() => {
  const [selectedShowFile, setSelectedShowFile] = useState(false);
  const createDropDownRef = useRef(null);
  const { pathForFile } = useGetPathUrl();
  const path = `/drive/folders/${pathForFile}`;
  const { data: driveFoldersData, isLoading: isLoadingDriveFoldersData } =
    useGetDriveFoldersQuery();
  const { isDragEnter } = useSelector((state) => state.DropZoneReducer);

  const {
    searchLoader,
    fnOnSearchInputChange,
    fnOnSearch,
    fnOnEnter,
    fnFilterFilesAndFolders,
  } = useSearchManager();

  const [src, setSrc] = useState("");
  const [showFile, setShowFile] = useState(false);
  const [fileName, setFileName] = useState();
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const [showCreatingFolder, setShowCreatingFolder] = useState(false);
  const dispatch = useDispatch();
  const driveFolders = driveFoldersData?.data?.folders || [];
  const files = driveFoldersData?.data?.files || [];
  const itemTypeModel = useSelector(selectItemTypeModel);

  const filteredFolders = fnFilterFilesAndFolders(driveFolders);
  const filteredFiles = fnFilterFilesAndFolders(files);

  const isFoldersExist = filteredFolders?.length > 0;
  const isFilesExist = filteredFiles?.length > 0;

  useEffect(() => {
    dispatch(resetBreadcrumbs());
  }, [dispatch]);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setDragEnter());
  };

  useSelectAllItems([...filteredFiles, ...filteredFolders]);

  useOutsideClick(createDropDownRef, () => setShowCreatingFolder(false));

  const fnRightClick = (event) => {
    event.preventDefault();
    const { clientX: x, clientY: y } = event;
    const dropdownWidth = 200;
    const dropdownHeight = 150;
    const rightOverflow = x + dropdownWidth > window.innerWidth;
    const bottomOverflow = y + dropdownHeight > window.innerHeight;

    setModalPosition({
      x: rightOverflow ? window.innerWidth - dropdownWidth : x,
      y: bottomOverflow ? window.innerHeight - dropdownHeight : y,
    });

    setShowCreatingFolder(true);
  };
  return (
    <>
      <div
        onDragEnter={handleDragEnter}
        onContextMenu={fnRightClick}
        className="flex flex-col w-full gap-[20px] h-full overflow-y-scroll"
      >
        {showFile && src && (
          <ShowFile
            showFile={showFile}
            url={src}
            pathName={pathForFile}
            fileName={fileName}
            selectedShowFile={selectedShowFile}
            setShowFile={setShowFile}
          />
        )}
        <BreadCrumb />
        <div className="flex items-center justify-between bg-[white] w-fit rounded-[25px] drop-shadow mx-1 px-[8px]">
          <input
            type="text"
            placeholder="Search"
            className="text-[16px] w-[30vw] cursor-text p-[12px] rounded-[25px] ml-[5px] outline-none placeholder:text-[#112D4E] text-112D4E"
            onChange={(event) => fnOnSearchInputChange(event)}
            onKeyDown={fnOnEnter}
          />
          <DZBtn
            onClick={fnOnSearch}
            solid
            className={"rounded-full !px-[12px]"}
            textStyle={"hidden"}
            btnIcon={
              searchLoader ? (
                <img
                  className="rounded-full w-[16.5px] animate-spin"
                  src={"/circle.png"}
                />
              ) : (
                <SearchIcon className="cursor-pointer" iconColor="#f9F7F7" />
              )
            }
          />
        </div>
        <div className="flex flex-col gap-[20px] overflow-y-scroll max-h-[100vh] flex-1 last:pb-[54px]">
          {isLoadingDriveFoldersData ? (
            <SimpleLoader />
          ) : isFilesExist || isFoldersExist ? (
            <>
              <ViewFolders
                filteredFolders={filteredFolders}
                itemTypeModel={itemTypeModel}
                isFoldersExist={isFoldersExist}
                pathForFile={pathForFile}
              />
              <ViewFiles
                filteredFiles={filteredFiles}
                itemTypeModel={itemTypeModel}
                setSelectedShowFile={setSelectedShowFile}
                isFilesExist={isFilesExist}
                pathForFile={pathForFile}
                setSrc={setSrc}
                setShowFile={setShowFile}
                setFileName={setFileName}
              />
            </>
          ) : (
            <EmptyLoader />
          )}
        </div>
        {isDragEnter && <UploadData id="uploadData" />}{" "}
      </div>
      {showCreatingFolder && (
        <CreateDropDown
          dropRef={createDropDownRef}
          showCreatingFolder={showCreatingFolder}
          setShowCreatingFolder={setShowCreatingFolder}
          className={"absolute z-[2] !h-fit w-fit shadow"}
          style={{ top: modalPosition.y, left: modalPosition.x }}
        />
      )}
    </>
  );
});

export default DriveHomes;
