import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { juriiApi } from "./storeApis";
import { juriiDriveApi } from "./juriiDriveApis";
import { passwordManagerApi } from "./passwordManagerApis";
import UserReducer from "./UserReducer";
import SnackBarReducer from "./SnackBarReducer";
import { persistReducer, persistStore } from "redux-persist";

import storage from "redux-persist/lib/storage";
import ComposeModelReducer from "./ComposeModelReducer";
import DropZoneReducer from "./DropZoneReducer";
import MoveReducer from "./MoveReducer";
import OperationalBarReducer from "./OperationalBarReducer";
import ConditionalModelReducer from "./ConditionalModelReducer";
import CardGridReducer from "./CardGridReducer";
import LoadingReducer from "./LoadingReducer";
import BreadCrumbReducer from "./BreadCrumbReducer";
import DriveFilterReducer from "./DriveFilterReducer";
import CreateFolderReducer from "./CreateFolderReducer";
import UserAccountsReducer from "./UserAccountsReducer";
import PasswordManagerReducer from "./PasswordManagerReducer";

const snackBarReducer = "SnackBarReducer";
const composeModelReducer = "ComposeModelReducer";
const operationalBarReducer = "OperationalBarReducer";
const dropZoneReducer = "DropZoneReducer";
const moveReducer = "MoveReducer";
const conditionalModelReducer = "ConditionalModelReducer";
const cardGridReducer = "CardGridReducer";
const loadingReducer = "LoadingReducer";
const breadCrumbReducer = "BreadCrumbReducer";
const driveFilterReducer = "DriveFilterReducer";
const createFolderReducer = "CreateFolderReducer";
const passwordManagerReducer = 'PasswordManagerReducer';

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    juriiApi.reducerPath,
    juriiDriveApi.reducerPath,
    passwordManagerApi.reducerPath,
    snackBarReducer,
    composeModelReducer,
    operationalBarReducer,
    dropZoneReducer,
    moveReducer,
    conditionalModelReducer,
    cardGridReducer,
    loadingReducer,
    // breadCrumbReducer,
    driveFilterReducer,
    createFolderReducer,

    passwordManagerReducer
  ],
};
const appReducer = combineReducers({
  SnackBarReducer,
  UserReducer,
  ComposeModelReducer,
  OperationalBarReducer,
  DropZoneReducer,
  MoveReducer,
  ConditionalModelReducer,
  CardGridReducer,
  LoadingReducer,
  BreadCrumbReducer,
  DriveFilterReducer,
  CreateFolderReducer,
  UserAccountsReducer,
  PasswordManagerReducer,
  [juriiApi.reducerPath]: juriiApi.reducer,
  [juriiDriveApi.reducerPath]: juriiDriveApi.reducer,
  [passwordManagerApi.reducerPath]: passwordManagerApi.reducer,
});
const rootReducer = (state, action) => {
  if (action.type == "logout") {
    state = undefined;
  }
  return appReducer(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["ConditionalModelReducer/showModal"],
      },
    }).concat(
      juriiApi.middleware,
      juriiDriveApi.middleware,
      passwordManagerApi.middleware
    ),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);
