import React, { useEffect, useState } from "react";
import DZText from "../shared/text/DZText";
import CheckInput from "../shared/checkInput/CheckInput";
import {
  DownloadIcon,
  EditIcon,
  DeleteIcon,
  BrokenIcon,
  CrossIcon,
  ReplyIcon,
  ForwardIcon,
} from "../../icons/generalIcons/Icons";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import {
  selectSelectedFilePath,
  setSelectedFilePath,
} from "../../redux/OperationalBarReducer";
import { useDispatch } from "react-redux";
import useDeleteFileFolder from "../../lib/useDeleteFileFolder";
import { useLocation } from "react-router-dom";
import { hideModal, showModal } from "../../redux/ConditionalModelReducer";
import FilterBar from "../juriDrive/filterBar/FilterBar";
import { setIsLoading } from "../../redux/LoadingReducer";
import useKeyPress from "../../lib/useKeyPress";
import useDownloadFileFolder from "../../lib/useDownloadFileFolder";
import { Config, SIDE_BAR_TYPES } from "../../constants/Index";

const OperationBar = ({ type }) => {
  const location = useLocation();
  const fileFolderPath = useSelector(selectSelectedFilePath);
  const { handleDelete, isDeleteLoading } = useDeleteFileFolder();
  const dispatch = useDispatch();
  const [actived, setActived] = useState(false);
  console.log(fileFolderPath, "fileFolderPath");
  useEffect(() => {
    handleUnSelect();
  }, [location?.pathname]);
  useEffect(() => {
    dispatch(setIsLoading(isDeleteLoading));
  }, [isDeleteLoading]);

  const toggleActived = () => {
    setActived(!actived);
  };

  const handleUnSelect = () => {
    dispatch(setSelectedFilePath([]));
  };
  const handleDeleteClick = () => {
    dispatch(
      showModal({
        solid: true,
        isVisible: true,
        title: "Delete",
        message: "Are you sure to delete this item?",
        okCallback: () => {
          handleDelete(fileFolderPath);
        },
        cancelCallback: () => {
          dispatch(hideModal());
        },
      })
    );
  };

  useKeyPress("Delete", () => {
    if (fileFolderPath?.length > 0) {
      handleDeleteClick();
    }
  });
  const ViewSelectedFilesName = () => {
    return (
      <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
        {fileFolderPath?.map((filename) => (
          <h3>{filename?.split("/")?.pop()}</h3>
        ))}
      </div>
    );
  };

  const handleDownloadClick = () => {
    dispatch(
      showModal({
        solid: true,
        isVisible: true,
        title: "Downolad",
        message: "Are you sure to download this item?",
        okCallback: async () => {
          await handleDownloadMultipleItem(fileFolderPath);
          dispatch(hideModal());
        },
        cancelCallback: () => {
          dispatch(hideModal());
        },
      })
    );
  };

  const handleDownloadMultipleItem = (fileFolderPaths) => {
    if (fileFolderPaths.length > 0) {
      fileFolderPaths.forEach(async (fileFolderPath) => {
        const url = `${Config.baseCorsProductUrl}${fileFolderPath}`;
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error(`Failed to fetch ${url}`);
          }
          const blob = await response.blob();
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = downloadUrl;
          const fileName = fileFolderPath.split('/').pop();
          link.download = fileName;
  
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(downloadUrl);
          document.body.removeChild(link);
        } catch (error) {
          console.error(`Error downloading ${url}:`, error);
        }
      });
    }
  };
  


  return (
    <>
      {fileFolderPath.length > 0 ? (
        <div
          className={`flex items-center justify-between w-full ${
            fileFolderPath.length > 0
              ? "border-solid border-[#112D4E] border-indigo-500"
              : ""
          } `}
        >
          <div className="flex items-center gap-[12px]">
            {fileFolderPath.length > 0 && (
              <span
                onClick={handleUnSelect}
                className="bg-[#112D4E] p-[6px] rounded-full cursor-pointer"
              >
                <CrossIcon
                  iconColor="#F9F7F7"
                  className={"w-[20px] h-[20px]  cursor-pointer"}
                />
              </span>
            )}
            {/* <Tooltip arrow title="Click to select all the emails">
              <span onClick={toggleActived}>
                <CheckInput actived={actived} />
              </span>
            </Tooltip> */}
            {/* <DZText
              children={
                type == "drive" ? <ViewSelectedFilesName /> : "XYZ Person Name"
              }
              small
              className={"text-112D4E cursor-text"}
            /> */}
          </div>
          <div className="flex items-center gap-[20px]">
            {type == SIDE_BAR_TYPES.email && (
              <>
                <Tooltip arrow title="Forward the email">
                  <span className="bg-[#F9F7F7] p-[10px] rounded-full cursor-pointer">
                    <ForwardIcon
                      className={"w-[16px] h-[16px]  cursor-pointer"}
                    />
                  </span>
                </Tooltip>
                <Tooltip arrow title="Reply to the email">
                  <span className="bg-[#F9F7F7] p-[10px] rounded-full cursor-pointer">
                    <ReplyIcon
                      className={" w-[16px] h-[16px] cursor-pointer "}
                    />
                  </span>
                </Tooltip>
              </>
            )}
            {type == SIDE_BAR_TYPES.drive && (
              <>
                <Tooltip arrow title="Download">
                  <span onClick={handleDownloadClick} className="bg-[#F9F7F7] p-[10px] rounded-full cursor-pointer">
                    <DownloadIcon
                      className={"w-[16px] h-[16px]  cursor-pointer"}
                    />
                  </span>
                </Tooltip>
                {/* <Tooltip arrow title="Share with others">
                  <span className="bg-[#F9F7F7] p-[10px] rounded-full cursor-pointer">
                    <BrokenIcon
                      className={"w-[16px] h-[16px]  cursor-pointer"}
                    />
                  </span>
                </Tooltip>
                <Tooltip arrow title="Rename">
                  <span className="bg-[#F9F7F7] p-[10px] rounded-full cursor-pointer">
                    <EditIcon className={"w-[16px] h-[16px]  cursor-pointer"} />
                  </span>
                </Tooltip> */}
              </>
            )}
            {type == SIDE_BAR_TYPES.passwordManager && (
              <>
                <Tooltip arrow title="Rename">
                  <span className="bg-[#F9F7F7] p-[10px] rounded-full cursor-pointer">
                    <EditIcon className={"w-[16px] h-[16px]  cursor-pointer"} />
                  </span>
                </Tooltip>
              </>
            )}
            <Tooltip
              arrow
              title="Delete"
              onClick={() => {
                handleDeleteClick();
              }}
            >
              <span className="bg-[#F9F7F7] p-[10px] rounded-full cursor-pointer">
                <DeleteIcon className={"w-[16px] h-[16px]  cursor-pointer"} />
              </span>
            </Tooltip>
          </div>
        </div>
      ) : type == SIDE_BAR_TYPES.passwordManager ? (
        <></>
      ) : (
        <FilterBar type={SIDE_BAR_TYPES.drive} />
      )}
    </>
  );
};

export default OperationBar;
