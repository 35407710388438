import { useCallback } from "react";

const useEnterKeySubmit = (handleLogin) => {
  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter") {
        handleLogin(e);
      }
    },
    [handleLogin]
  );

  return { handleKeyDown };
};

export default useEnterKeySubmit;
