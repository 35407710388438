import React from 'react'

const SelectDropDown = ({ children, className, dropDownRef = false }) => {
  return (
    <div ref={dropDownRef} className={` ${className} flex flex-col gap-[12px] p-[12px] bg-F9F7F7 rounded-[12px]`}>
      {children}
    </div>
  )
}

export default SelectDropDown;