import React, { useEffect, useRef, useState } from "react";
import DZBtn from "../buttons/DZBtn";
import { AddIcon } from "../../../icons/generalIcons/Icons";
import CreateDropDown from "../dropDowns/CreateDropDown";
import AddUpdateEmailSetting from "../modals/AddUpdateEmailSetting";
import { SIDE_BAR_TYPES } from "../../../constants/Index";

function SidebarMainButton({ sideBarType, clickOnButton, showCreatingFolder, setShowCreatingFolder, }) {
  const [emailSettingModal, setEmailSettingModal] = useState(false);

  const isDrive = sideBarType == SIDE_BAR_TYPES.drive;
  const isEmail = sideBarType == SIDE_BAR_TYPES.email;
  const isSettings = sideBarType == SIDE_BAR_TYPES.settings;
  const isPassManager = sideBarType == SIDE_BAR_TYPES.passwordManager;

  return (
    <div className="flex flex-col gap-[20px] relative w-full max-sm:w-fit">
      {/* {sideBarType === "email" && (
        <DZBtn
          fullRadius
          btnIcon={<AddIcon className={"cursor-pointer"} />}
          toolTipPlacement={"bottom"}
          toolTipText={"Create or upload folder/file"}
          children={`${
            sideBarType == SIDE_BAR_TYPES.drive ? "New" : "Update Email Setting"
          }`}
          textStyle={"text-112D4E cursor-pointer"}
          className={
            "bg-DBE2EF w-full px-[32px] py-[10px] gap-[4px] z-[1] max-lg:hidden"
          }
          onClick={() => {
            setEmailSettingModal(true);
          }}
        /> 
      )}
      {emailSettingModal && (
        <AddUpdateEmailSetting
          emailSettingModal={emailSettingModal}
          setEmailSettingModal={setEmailSettingModal}
          cancel={() => setEmailSettingModal(false)}
        />
      )} */}

      {/* {(sideBarType === SIDE_BAR_TYPES.email || isDrive) && ( */}
      <DZBtn
        fullRadius
        btnIcon={<AddIcon className={"cursor-pointer"} />}
        toolTipPlacement={"bottom"}
        toolTipText={ isEmail ? "Write an Email" : isDrive ? "Create or upload folder/file" : isPassManager ? '' : ''}
        children={`${isDrive ? "New" : isEmail ? "Compose" : isSettings ? "Create Account" : "New"}`}
        textStyle={"text-112D4E cursor-pointer max-lg:hidden"}
        className={`bg-DBE2EF max-sm:w-fit w-full px-[32px] py-[10px] gap-[4px] z-[1] max-lg:px-[10px] ${showCreatingFolder ? " border-[#3F72AF] border-2 shadow " : ""
          }`}
        onClick={() => {
          clickOnButton();
        }}
      />
      {/* )} */}

      {isDrive && (
        <CreateDropDown
          showCreatingFolder={showCreatingFolder}
          setShowCreatingFolder={setShowCreatingFolder}
          className={
            " absolute z-[2] top-[120%] !h-fit max-[420px]:left-0 max-[420px]:translate-x-0 w-full shadow max-lg:w-[200px] max-lg:top-0 max-lg:left-[180%] max-sm:top-auto max-sm:left-[50%] max-sm:-translate-x-[50%] bottom-[150%]"
          }
        />
      )}

      {/* {sideBarType === "passwordManager" && } */}
      {/* 
      {(sideBarType === "email" || isDrive) && (
        <DZBtn
          fullRadius
          btnIcon={<AddIcon className={"cursor-pointer"} />}
          toolTipPlacement={"bottom"}
          toolTipText={"Create or upload folder/file"}
          children={`${
              sideBarType == SIDE_BAR_TYPES.drive ? "New"
              : sideBarType == "email" ? "Compose"
              : ""
          }`}
          textStyle={"text-112D4E cursor-pointer max-lg:hidden"}
          className={
            "bg-DBE2EF w-full max-lg:w-fit px-[32px] py-[10px] gap-[4px] z-[1] lg:hidden max-lg:px-[10px]"
          }
          onClick={() => {
            clickOnButton();
          }}
        />
      )} */}
    </div>
  );
}

export default SidebarMainButton;
