import React from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { Config } from "../../constants/Index";
import { useNavigate } from "react-router-dom";
import { getLocalStorage, setLocalStorage } from "../../components/localStorage";
import { useDispatch } from "react-redux";
import { setSelectedLoginUser } from "../../redux/UserReducer";
// import Microsoft from "../../icons/productIcon/Microsoft"; // You'll need to create this icon

import { useSnackBarManager } from "../../lib/customHooks/useSnackBarManager";
import { ROUTES } from "../../reactRoute/RouteConstants";

const MicrosoftLoginMail = ({ onSuccess, onFailure }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { instance, accounts } = useMsal();
  const { fnShowSnackBar } = useSnackBarManager();

  const handleMicrosoftSignIn = async () => {
    const loginRequest = {
      scopes: ["user.read", "mail.read"],
      // prompt: "select_account",
    };

    try {
      const response = await instance.loginPopup(loginRequest);
      if (response.accessToken) {
        await handleAuthenticationWithBackend(response.accessToken);
      }
    } catch (error) {
      console.error("Microsoft Sign-In was unsuccessful:", error);
      if (onFailure) onFailure(error);
      fnShowSnackBar('Account cannot be created, please try again!');
    }
  };

  const handleAuthenticationWithBackend = async (accessToken) => {
    const userToken = getLocalStorage(Config.userToken);
    
    try {
      const response = await fetch(`${Config.serverUrl}api/microsoft/auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`
        },
        body: JSON.stringify({ 
          token: accessToken
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log("Server response:", data);
        const fetchUserToken = data?.data?.access_token;
  
        if (fetchUserToken) {
          setLocalStorage(Config.userToken, fetchUserToken);
          dispatch(setSelectedLoginUser(fetchUserToken));
          if (onSuccess) onSuccess(fetchUserToken);
          fnShowSnackBar('Account created successfully!');
          navigate(ROUTES.juriiSettings.accounts);
        }
      } else {
        fnShowSnackBar('Failed to authenticate with the backend');
      }
    } catch (error) {
      console.error('Microsoft Sign-In was unsuccessful:', error);
      if (onFailure) onFailure(error);
      fnShowSnackBar('Account cannot be created, please try again!');
    }
  };

  return (
    <div style={{width:'100%', margin:'auto'}}> 
      <span className="w-[100%] cursor-pointer border-[1px] py-[13px] flex justify-center items-center rounded-[10px] border-[#DBE2EF]" onClick={handleMicrosoftSignIn}>  
        {/* <Microsoft /> */}
        Microsoft
      </span>
    </div>
  );
};

export default MicrosoftLoginMail;