import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
    name:'PasswordManagerReducer',
    initialState : {
        loginModal : null,
        paymentModal : null,
        idModal : null
    },
    reducers : {

        setSelectedLoginModal : (state, action) => {  state.loginModal = action.payload },

        hideSelectedLoginModal : (state) => { state.loginModal = null  },

        setSelectedPaymentModal : (state, action) => { state.paymentModal = action.payload },

        hideSelectedPaymentModal : (state) => { state.paymentModal = null },

        setSelectedIdModal : (state, action) => { state.idModal = action.payload },

        hideSelectedIdModal : (state) => { state.idModal = null }
    }
})

export const { setSelectedLoginModal, hideSelectedLoginModal, setSelectedPaymentModal, hideSelectedPaymentModal, setSelectedIdModal, hideSelectedIdModal } = slice.actions;

export const selectedLoginModalSelector = (state)=> state.PasswordManagerReducer.loginModal;
export const selectedPaymentModalSelector = (state)=> state.PasswordManagerReducer.paymentModal;
export const selectedIdModalSelector = (state)=> state.PasswordManagerReducer.idModal;

export default slice.reducer;