import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import DriveCard from '../../components/shared/cards/DriveCard';
import { EmptyLoader } from '../../components/loader/Loaders';
import { useSelector } from 'react-redux';
import { selectIsCardSquare } from '../../redux/CardGridReducer';
import { Config } from '../../constants/Index';

const ViewFiles = React.memo(({ filteredFiles, itemTypeModel,setSelectedShowFile, isFilesExist, pathForFile, setSrc, setShowFile, setFileName }) => {
  const isCardSquare = useSelector(selectIsCardSquare);
  return (
    <>
      {(itemTypeModel === "Files" || itemTypeModel === "All") && isFilesExist ? (
        <div className="flex flex-col gap-[1px]">
          <div className="text-112D4E font-[500] text-[22px]">Drive Files</div>
          <div className={`${isCardSquare ? "grid max-xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 max-[420px]:grid-cols-1 grid-cols-6 max-2xl:grid-cols-5 " : "flex flex-col"} gap-[24px]`}>
            {filteredFiles?.map((file, index) => (
              <Droppable droppableId={file?.name} key={file?.id}>
                {(provided) => (
                  <span
                    onDoubleClick={() => {
                      setSrc(`${Config.baseProductUrl}${file?.name}`);
                      setSelectedShowFile(file);
                      setShowFile(true);
                      setFileName(file?.name);
                    }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <DriveCard cardType="file" folder={file} pathName={pathForFile} index={index} />
                    {provided.placeholder}
                  </span>
                )}
              </Droppable>
            ))}
          </div>
        </div>
      ) : (
        itemTypeModel === "Files" && <EmptyLoader />
      )}
    </>
  );
});

export default ViewFiles;
