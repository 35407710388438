import React from "react";

import './privacy.css'
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const SecurityMeasures = () => {

  const location = useLocation();

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[location.pathname]);

  return (
    <div className="flex items-center w-full min-h-full justify-between px-[6vw] py-[64px] gap-[24px] max-[1004px]:flex-col">
      <div className="privacy__page">
        <div className="inner__div">
          <div className="inner__container">
            <h3 className="normal__heading">Jurri Security Measures</h3>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">At Jurri, we prioritize the security and privacy of your data. Here's how we protect your information:</h3>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Data Encryption</h3>
            <p>
                All data transmitted between your device and our servers is encrypted using industry-standard TLS (Transport Layer Security) protocols.
            </p>
            <p>
                Emails are encrypted at rest using AES-256 encryption.
            </p>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Authentication</h3>
            <p>
                We use OAuth 2.0 for secure authentication with email providers.
            </p>
            <p>
                Two-factor authentication (2FA) is supported and encouraged for all accounts.
            </p>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Data Access</h3>
            <p>
                Our employees do not have direct access to your email content.
            </p>
            <p>
                All access to systems is logged and monitored for suspicious activity.
            </p>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Compliance</h3>
            <p>
                We comply with GDPR and CCPA data protection regulations.
            </p>
            <p>
                Regular security audits are conducted to ensure the highest standards of data protection.
            </p>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Reporting Security Issues</h3>
            <p>
                If you discover a security issue, please email us at security@danzeetech.com.
            </p>
          </div>
          <div className="inner__container">
            <p>
                We are committed to continuously improving our security measures to protect your data.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityMeasures;