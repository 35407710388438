import React, { useState } from 'react';
import AddUpdateLoginModal from '../../components/shared/modals/AddUpdateLoginModal';
import PasswordManagerBar from '../passwordManagerBar/PasswordManagerBar';
import { useHandleModalManager } from '../../lib/customHooks/useHandleModalManager';
import { useDeleteLoginMutation, useDeleteMultipleLoginMutation, useGetLoginsQuery } from '../../redux/passwordManagerApis';
import { BrokenIcon, ClockIcon, CrossIcon, DeleteIcon, EditIcon, SearchIcon, StarIcon } from '../../icons/generalIcons/Icons';
import { Tooltip } from '@mui/material';
import CheckInput from '../../components/shared/checkInput/CheckInput';
import TimeDisplay from '../../components/ui/error/TimeDisplay';
import DZText from '../../components/shared/text/DZText';
import { useSnackBarManager } from '../../lib/customHooks/useSnackBarManager';
import DZBtn from '../../components/shared/buttons/DZBtn';
import DeleteModal from '../../components/shared/modals/DeleteModal';
import { EmptyLoader, SimpleLoader } from '../../components/loader/Loaders';
import { Config } from '../../constants/Index';
import { useSearchManager } from '../../lib/customHooks/useSearchManager';
import { useSelector } from 'react-redux';
import { hideSelectedLoginModal, selectedLoginModalSelector } from '../../redux/PasswordManagerReducer';
import { useDispatch } from 'react-redux';

const PasswordManagerLogins = () => {

  const dispatch = useDispatch();

  const { data: allLoginsData, isLoading: isLoadingAllLoginsData } = useGetLoginsQuery();
  const [deleteMultipleLogins, { isLoading: isLoadingMultipleLogins }] = useDeleteMultipleLoginMutation();

  const { fnShowSnackBar } = useSnackBarManager();
  const { searchLoader, fnOnSearchInputChange, fnOnEnter, fnOnSearch, fnFilterLogins } = useSearchManager();

  const isShowAddLoginModal = useSelector(selectedLoginModalSelector);

  const [loginModal, setLoginModal] = useState({ update: null });
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedLogins, setSelectedLogins] = useState([]);
  const [selectedLoginData, setSelectedLoginData] = useState(false);

  const { modalRef } = useHandleModalManager({ modal: loginModal, setModal: setLoginModal });

  const filteredLogins = fnFilterLogins(allLoginsData);
  const isExistLoginsData = filteredLogins?.length > 0;
  const isExistSelectedLogins = selectedLogins?.length > 0;

  const fnDeleteWebsiteDetail = () => {
    const ids = selectedLogins?.map((login)=> login?.id);
    
    deleteMultipleLogins({ids}).unwrap().then((payload) => {
      if (payload.success) {
        setDeleteModal(false);
        setSelectedLogins([]);
        fnShowSnackBar('Deleted Login Items Successfully!');
      }
    }
    ).catch((error) => {
      fnShowSnackBar('Something went wrong, please try agian!');
    });
  };

  const fnEmptyData = () => {
    setSelectedLogins([]);
  };

  const fnOnClickCheckInput =(e, detail)=> {
    e.stopPropagation();
    const isAlreadyExist = selectedLogins?.find((login)=> login?.id == detail?.id);
    if(isAlreadyExist) {
      const removedLogins = selectedLogins?.filter((login)=> login?.id != detail?.id);
      setSelectedLogins(removedLogins);
    } else  {
      setSelectedLogins([...selectedLogins, detail]);
    }
  };

  return (
    <div className="flex flex-wrap gap-[24px] h-full overflow-y-scroll last:pb-[56px]">

      { (loginModal.update || isShowAddLoginModal) && 
        <AddUpdateLoginModal 
          modal={loginModal} 
          setModal={setLoginModal} 
          cancel={() => {setLoginModal(false); if(isShowAddLoginModal) { dispatch(hideSelectedLoginModal()) }}} 
          loginData={loginModal.update && selectedLoginData} 
          isUpdate={loginModal.update} 
          fnEmptyData={fnEmptyData} 
        />
      }

      <DZText className={'text-[#112D4E] w-full'} children={"Logins"} normal />

      <div className="flex items-center justify-between bg-[white] w-fit rounded-[25px] drop-shadow mx-1 px-[8px]">
        <input
          type="text"
          placeholder="Search"
          className="text-[16px] w-[30vw] cursor-text p-[12px] rounded-[25px] ml-[5px] outline-none placeholder:text-[#112D4E] text-112D4E"
          onChange={(event) => fnOnSearchInputChange(event)}
          onKeyDown={fnOnEnter}
        />
        <DZBtn onClick={fnOnSearch} solid className={'rounded-full !px-[12px]'} textStyle={'hidden'} btnIcon={ searchLoader ? <img className="rounded-full w-[16.5px] animate-spin" src={'/circle.png'}/> : <SearchIcon className="cursor-pointer" iconColor="#f9F7F7" /> } />
      </div>

      {isExistSelectedLogins && <div className={`flex items-center justify-between w-full h-[46px]`}>

        <div className="flex items-center gap-[12px]">

          <span onClick={() => setSelectedLogins([])} className="bg-[#112D4E] p-[6px] rounded-full cursor-pointer" >
            <CrossIcon
              iconColor="#F9F7F7"
              className={"w-[20px] h-[20px]  cursor-pointer"}
            />
          </span>

          {/* <DZText children={selectedLoginDetail?.websiteName} small className={"text-112D4E cursor-text"} /> */}

        </div>

        <div className="flex items-center gap-[20px]">

          <Tooltip arrow title="Delete" >
            <span onClick={() => setDeleteModal(true)} className="bg-[#F9F7F7] p-[10px] rounded-full cursor-pointer">
              <DeleteIcon className={"w-[16px] h-[16px]  cursor-pointer"} />
            </span>
          </Tooltip>

        </div>

      </div>}

      {isLoadingAllLoginsData ? <SimpleLoader />
        : isExistLoginsData ? filteredLogins?.map((detail) => {

          const websiteUrl = detail?.website;
          const websiteName = Config.fnGetWebsiteName(detail?.website);

          return (
            <div className={`group flex items-center px-[24px] py-[12px] bg-F9F7F7 gap-[24px] w-full rounded-[10px] cursor-pointer max-sm:flex-col max-sm:gap-[4px] max-sm:px-[18px] max-sm:py-[10px] max-sm:items-start`}>

              <Tooltip arrow title="Click to select it">
                <span
                  onClick={(e) => fnOnClickCheckInput(e, detail)}
                  className="cursor-pointer"
                >
                  <CheckInput active={selectedLogins?.some((login)=> login?.id == detail?.id)} />
                </span>
              </Tooltip>

              <div className="flex flex-col gap-[4px] max-w-full w-full">

                <DZText children={websiteName} className={'text-[#112D4E]'} />

                <DZText children={detail?.email} className={'text-[#3F72AF] truncate'} />

              </div>

              <a href={websiteUrl} target='_blank' className="h-fit opacity-0  transition group-hover:opacity-100 flex items-center gap-[4px] max-w-full w-full cursor-pointer max-sm:opacity-100">
                <BrokenIcon className={'w-[22px] h-[22px]'} iconColor='#3F72AF' />
                <DZText children={'Visit site'} className={'text-3F72AF cursor-pointer'} />
              </a>

              <div className="flex items-center gap-[24px]">
                <Tooltip arrow title="Edit">
                  <span onClick={() => { setSelectedLoginData(detail); setLoginModal({ ...loginModal, update: true })}} className="opacity-0 group-hover:opacity-100 transition-all bg-[#FFF] p-[10px] rounded-full cursor-pointer">
                    <EditIcon className={"cursor-pointer"} />
                  </span>
                </Tooltip>
                <Tooltip arrow title="Delete" >
                  <span onClick={() => { setSelectedLogins([detail]); setDeleteModal(true)}} className="opacity-0 group-hover:opacity-100 transition-all bg-[#FFF] p-[10px] rounded-full cursor-pointer">
                    <DeleteIcon className={"cursor-pointer"} />
                  </span>
                </Tooltip>
              </div>

            </div>
          )
        }) : <EmptyLoader />
      } 

      {deleteModal && <DeleteModal onClickYes={() => fnDeleteWebsiteDetail()} okTitle={isLoadingMultipleLogins && 'Loading'} onClickNo={() => {setDeleteModal(false); fnEmptyData()}} />}

    </div>
  )
}

export default PasswordManagerLogins;