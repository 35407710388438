import React from "react";
import {
  useMoveToTrashFileMutation,
  useRemoveDriveFileMutation,
  useRestoreFileMutation,
} from "../redux/juriiDriveApis";
import { useSnackBarManager } from "./customHooks/useSnackBarManager";
import { useLocation } from "react-router-dom";
import { hideModal } from "../redux/ConditionalModelReducer";
import { useDispatch } from "react-redux";
import { setSelectedFilePath } from "../redux/OperationalBarReducer";

function useDeleteFileFolder() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [restoreFile, { isLoading: isRestoreFileLoading }] =
  useRestoreFileMutation();
  const { fnShowSnackBar } = useSnackBarManager();
  const [moveToTrashFile, { isLoading }] = useMoveToTrashFileMutation();
  const [removeDriveFile, { isLoading: isRemoveFolderLoading }] =
    useRemoveDriveFileMutation();

  const findFileType = (pathName) => {
    if (Array?.isArray(pathName)) {
      return pathName?.map((path) => (path?.includes(".") ? "file" : "folder"));
    } else {
      return pathName?.includes(".") ? ["file"] : ["folder"];
    }
  };
  const handleRestoreFile = (id, action) => {
    restoreFile({
      id: id,
    })
      .then(() => {
        if (action) {
          action();
        }
        fnShowSnackBar("File Restored Successfully", false);
      })
      .catch((err) => {
        fnShowSnackBar("Failed to restore file", true);
      });
  };
  const handleUnSelect = () => {
    dispatch(setSelectedFilePath([]));
  };
  const handleDelete = async (pathName) => {
    if (!pathName) {
      fnShowSnackBar("Path name is required", true);
      return;
    }

    const isTrash = location?.pathname?.includes("trash");

    let modifiedPathNames;
    if (Array.isArray(pathName)) {
      modifiedPathNames = pathName.map((name) =>
        name.replace("/drive/folders", "")
      );
    } else {
      modifiedPathNames = pathName.replace("/drive/folders", "");
    }

    const names = Array.isArray(modifiedPathNames)
      ? modifiedPathNames
      : [modifiedPathNames];
    const modifyName = names?.map((name) =>
      name.replace(/\/{2,}/g, "/").replace(/^\//, "")
    );

    const types = findFileType(modifiedPathNames);
    try {
      if (isTrash) {
        await removeDriveFile({ paths: modifyName, types });
      } else {
        await moveToTrashFile({ names: modifyName, types });
      }
      handleUnSelect();
      dispatch(hideModal());
      fnShowSnackBar("Successfully deleted", false);
    } catch (error) {
      console.error("Error while deleting:", error);
      fnShowSnackBar("Failed to delete", true);
    }
  };

  const isDeleteLoading =
    isLoading || isRemoveFolderLoading || isRestoreFileLoading;

  return {
    handleDelete,
    handleRestoreFile,
    isDeleteLoading,
  };
}

export default useDeleteFileFolder;
