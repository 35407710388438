import React, { useEffect, useRef, useState } from "react";
import DZText from "../text/DZText";
import {
  DotsIcon,
  EditIcon,
  DeleteIcon,
  BrokenIcon,
  DownloadIcon,
  StarIcon,
  RestoreIcon,
} from "../../../icons/generalIcons/Icons.jsx";
import MoveModal from "../../juriDrive/moveModal/MoveModal.jsx";
import { useDispatch } from "react-redux";
import { setSourceFile } from "../../../redux/MoveReducer.js";
import {
  hideModal,
  showModal,
} from "../../../redux/ConditionalModelReducer.js";
import useDeleteFileFolder from "../../../lib/useDeleteFileFolder.js";
import useDownloadFileFolder from "../../../lib/useDownloadFileFolder.js";
import RenameModal from "../modals/RenameModal.jsx";
import useKeyPress from "../../../lib/useKeyPress.js";
import useOutsideClick from "../../../lib/useOutsideClick.js";
import useHandleDriveStar from "../../../lib/useHandleDriveStar.js";
import { setIsLoading } from "../../../redux/LoadingReducer.js";
import { Config } from "../../../constants/Index.js";
import { useDownloadManager } from "../../../lib/customHooks/useDownloadManager.js";
import DriveCardModal from "./DriveCardModal.jsx";
const DriveCardDropDown = ({
  setShowDropDown,
  showDropDown,
  cardType,
  pathName,
  trash,
  folder,
  xPos,
  yPos,
  isSelected,
}) => {
  const dropdownRef = useRef(null);
  const dropDownPopupRef = useRef(null);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const cleanedPathName = pathName.replace(/\/{2,}/g, "/");
  const clearedPathName = cleanedPathName
    .replace("/drive/folders", "")
    .replace(/^\//, "");
  const handleShowDropDown = (e) => {
    e.stopPropagation();
    setShowDropDown((prevState) => !prevState);
  };
  return (
    <>
      <span ref={dropdownRef}>
        <div className="p-[10px] cursor-pointer" onClick={handleShowDropDown}>
          <DotsIcon className="cursor-pointer" />
        </div>
        {showDropDown && (
          <DriveCardModal
            setShowDropDown={setShowDropDown}
            showDropDown={showDropDown}
            cardType={cardType}
            pathName={pathName}
            trash={trash}
            folder={folder}
            xPos={xPos}
            yPos={yPos}
            dropDownPopupRef={dropDownPopupRef}
            dropdownRef={dropdownRef}
            setShowMoveModal={setShowMoveModal}
            showMoveModal={showMoveModal}
            setShowRenameModal={setShowRenameModal}
          />
        )}
      </span>
      {showMoveModal && (
        <MoveModal
          setShowMoveModal={setShowMoveModal}
          cleanedPathName={clearedPathName}
        />
      )}
      {showRenameModal && (
        <RenameModal
          cancel={() => setShowRenameModal(false)}
          pathName={cleanedPathName}
          setShowRenameModal={setShowRenameModal}
        />
      )}
    </>
  );
};
export default DriveCardDropDown;
