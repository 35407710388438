import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../reactRoute/RouteConstants";

function Products() {
  return (
    <div className="flex flex-col gap-[20px] rounded-[20px] bg-[#F9F7F7] p-[30px]">
      <h1 className="text-[36px] font-[600] text-[#112D4E]">Products</h1>

      <p className="text-[#3F72AF]">
        Explore our suite of products designed to enhance your digital
        experience, including Jurii Drive, Jurii Mail, and Jurii Password
        Manager. Each product is presented with key features and benefits,
        allowing you to easily understand and utilize their full potential.
        Seamlessly switch between products to manage your files, emails, and
        passwords all in one place
      </p>

      <Link
        to={ROUTES.inbox}
        className="flex items-center gap-[12px]  rounded-[10px] transition-all hover:bg-[#e5e8f0] hover:px-[12px] hover:py-[8px] cursor-pointer"
      >
        <img
          src="https://fastly.picsum.photos/id/201/300/300.jpg?hmac=_rxkWLIRjtC0llnrLSRm7TvKgiQS_GquUeyXZnK9Lg8"
          alt=""
          className="w-[56px] rounded-[100%]  cursor-pointer"
        />
        <span className="text-[14px] text-[#112D4E] cursor-pointer">Jurii Mail</span>
      </Link>

      <Link
        to={ROUTES.drive}
        className="flex items-center gap-[12px] rounded-[10px] transition-all hover:bg-[#e5e8f0] hover:px-[12px] hover:py-[8px] cursor-pointer"
      >
        <img
          src="https://fastly.picsum.photos/id/201/300/300.jpg?hmac=_rxkWLIRjtC0llnrLSRm7TvKgiQS_GquUeyXZnK9Lg8"
          alt=""
          className="w-[56px] rounded-[100%]  cursor-pointer"
        />
        <span className="text-[14px] text-[#112D4E] cursor-pointer">Jurii Drive</span>
      </Link>

      <Link
        to={ROUTES.passwordManager}
        className="flex items-center gap-[12px] rounded-[10px] transition-all hover:bg-[#e5e8f0] hover:px-[12px] hover:py-[8px] cursor-pointer"
      >
        <img
          src="https://fastly.picsum.photos/id/201/300/300.jpg?hmac=_rxkWLIRjtC0llnrLSRm7TvKgiQS_GquUeyXZnK9Lg8"
          alt=""
          className="w-[56px] rounded-[100%] cursor-pointer"
        />
        <span className="text-[14px] text-[#112D4E] cursor-pointer">Jurii Password Manager</span>
      </Link>
    </div>
  );
}

export default Products;
