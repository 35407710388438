import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Config } from "./constants/Index";
import { GoogleOAuthProvider } from "@react-oauth/google";
// microsoft
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication,EventType } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "6229ce28-da0b-441e-ae3b-bbf478fd2b98",
    authority: "https://login.microsoftonline.com/f8cdef31-a31e-4b4a-93e4-5f571e91255a",
    // redirectUri: "https://jurri.danzeetech.com/inbox",
    redirectUri: "http://localhost:3000",
    navigateToLoginRequestUrl:false,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);



if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length> 0) {
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0] );
}

msalInstance.addEventCallback((event)=>{
  if (event.eventType===EventType.LOGIN_SUCCESS && event.payload.account) {
    const account=event.payload.account;
    msalInstance.setActiveAccount(account)
  }
})
  // microsoft







const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={Config.googleClientId}>
      <PersistGate loading={null} persistor={persistor}>
        <App instance={msalInstance} />
      </PersistGate>
    </GoogleOAuthProvider>
  </Provider>
);








