import React, { useState } from "react";
import { Tooltip } from "@mui/material";

import { StarIcon, ClockIcon, DeleteIcon } from "../../../icons/generalIcons/Icons";

import CheckInput from "../checkInput/CheckInput";
import DZText from "../text/DZText";
import MobileMailCard from "../mobileMailCard/MobileMailCard";
import TimeDisplay from "../../ui/error/TimeDisplay";
import { useDeleteDraftMutation } from "../../../redux/storeApis";
import { useSnackBarManager } from "../../../lib/customHooks/useSnackBarManager";
import ConditionalModal from "../modals/ConditionalModal";
import { useDispatch } from "react-redux";
import { hideModal, showModal } from "../../../redux/ConditionalModelReducer";

const DraftCard = ({ email, className, selected, setSelectedDraftId }) => {

  const dispatch = useDispatch();
  const [ deleteDraft, { isLoading : isLoadingDeleteDraft } ] = useDeleteDraftMutation();

  const { fnShowSnackBar } = useSnackBarManager();

  const [active, setActive] = useState(false);
  const [isStarred, setIsStarred] = useState(false);

  const toggleActive = (e) => {
    e.stopPropagation();
    setActive(!active);
  };

  const toggleStarred = (e) => { 
    e.stopPropagation(); 
    setIsStarred(!isStarred);
  };

  const fnClickOnDeleteIcon =()=>{
    dispatch(
      showModal({
        isVisible: true,
        title: "Delete",
        message: "Are you sure to delete draft?",
        okCallback: () => fnDeleteDraft(),
        cancelCallback: () => {
          dispatch(hideModal());
          setSelectedDraftId(null);
        },
      })
    );
  };

  const fnDeleteDraft =async()=>{
    fnShowSnackBar('Loading...')
    try {
      const data = await deleteDraft(email?.id);
      if(data?.data?.success) {
        fnShowSnackBar('Draft deleted successfully');
        dispatch(hideModal());
        setSelectedDraftId(null);
      }
    } catch (error) {
      fnShowSnackBar('something went wrong!');
    }
  };

  return (
    <>
      <div
        className={` ${className} ${
          selected && "!bg-[#86BCFC30]"
        } flex items-center px-[24px] py-[12px] bg-F9F7F7 gap-[24px] w-full rounded-[10px] max-sm:hidden cursor-pointer `}
      >
        {/* Star Icon */}

        <Tooltip
          arrow
          title="Click to star it (you can see it in the starred page)"
        >
          <span className=" cursor-pointer">
            <StarIcon
              iconColor="#3F72AF"
              pointer
              bgColor={`${isStarred ? "#3F72AF" : ""}`}
              onClick={(e)=>toggleStarred(e)}
            />
          </span>
        </Tooltip>

        {/* Check Input */}

        <Tooltip arrow title="Click to select it">
          <span onClick={(e)=>toggleActive(e)} className=" cursor-pointer">
            <CheckInput active={active} />
          </span>
        </Tooltip>

        {/* Email Information */}

        <div
          className="flex flex-col gap-[4px] max-w-full w-full cursor-pointer"
          // onClick={toggleSelected}
        >
          <DZText
            children={email?.receiver ?? email?.subject ?? email?.body ?? '' }
            small
            className={
              "text-112D4E w-fit max-w-[160px] truncate  cursor-pointer"
            }
          />

          {/* <DZText
            children={email?.subject || ""}
            small
            className={
              "text-112D4E w-fit max-w-[160px] truncate  cursor-pointer"
            }
          /> */}
        </div>

        {/* Created At Time */}

        <div className="flex items-center gap-[12px] min-w-[130px]">
          <ClockIcon iconColor="#112D4E" className={" cursor-pointer"} />
          <TimeDisplay timestamp={email?.created_at}/>
          { active && <DeleteIcon onClick={(e)=> { e.stopPropagation(); fnClickOnDeleteIcon() }}/>}
        </div>
      </div>

      {/* Mobile Version of Mail Card */}

      <MobileMailCard
        isStarred={isStarred}
        active={active}
        toggleStarred={toggleStarred}
        toggleActive={toggleActive}
        // toggleSelected={toggleSelected}
        selected={selected}
      />
    </>
  );
};

export default DraftCard;
