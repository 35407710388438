import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectComposeModel,
  selectDefaultToMail,
  selectDraftData,
  selectedEmptyDraftData,
  selectFullComposeModel,
  selectOpenComposeModel,
  setComposeModel,
  setDefaultToMail,
  setDraftData,
  setFullComposeModel,
  setOpenComposeModel,
} from "../../redux/ComposeModelReducer";
import { useState } from "react";
import {
  useCreateDraftMutation,
  useSendEmailMutation,
} from "../../redux/storeApis";
import { useEffect } from "react";
import { useSnackBarManager } from "./useSnackBarManager";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../reactRoute/RouteConstants";
function useComposeModel() {
  const { pathname } = useLocation();
  const [data, setData] = useState({ receiver: [], subject: "", body: "" });
  const [addDraft] = useCreateDraftMutation();
  const [sendMail, { isLoading: isLoadingSendMail }] = useSendEmailMutation();
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const composeModel = useSelector(selectComposeModel);
  const openComposeModel = useSelector(selectOpenComposeModel);
  const fullComposeModel = useSelector(selectFullComposeModel);
  const draftData = useSelector(selectDraftData);
  const defaultToMail = useSelector(selectDefaultToMail);

  const [receiverCurrentValue, setReceiverCurrentValue] = useState("");
  const { fnShowSnackBar } = useSnackBarManager();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    if ((draftData?.receiver?.length > 0) || draftData?.subject || draftData?.body) {
      setData(draftData);
      // setSelectedFiles();
    } else {
      setData({
        ...data,
        receiver: defaultToMail || [],
      });
    }
  }, [draftData, defaultToMail]);

  const handleOpenComposeModel = (mail) => {
    if (mail?.length > 0) {
      handleSetMail(mail);
    } else {
      handleSetMail([]);
    }
    dispatch(setComposeModel(false));
    dispatch(setOpenComposeModel(false));
  };
  const handleSetMail = (data) => {
    dispatch(setDefaultToMail(data));
  };
  const handleCLose = () => {
    dispatch(setComposeModel(true));
    dispatch(setOpenComposeModel(false));
    dispatch(setDraftData(null));
  };
  const handleDraftOpenComposeModel = (data) => {
    if(draftData?.id == data?.id) {
      dispatch(setComposeModel(true));
      dispatch(setDraftData(null));
    } else {
      dispatch(setComposeModel(false));
      dispatch(setDraftData(data));
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleCancel = () => {
    if(pathname !== ROUTES.draft) {
      handleDraft();
    }
    setData({
      receiver: [],
      subject: "",
      body: "",
    });
    handleCLose();
  };
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("subject", data.subject);
    formData.append("body", data.body);
    data?.receiver?.forEach((email) => {
      formData.append("to[]", email);
    });
    selectedFiles?.forEach((file, index) => {
      formData.append(`attachments[${index}]`, file);
    });

    await sendMail(formData)
      .unwrap()
      .then((response) => {
        if (response.success) {
          setData({
            receiver: [],
            subject: "",
            body: "",
            attachments: [],
          });
          fnShowSnackBar("Email has been sent successfully");
          handleCLose();
        } else {
          fnShowSnackBar("Something is wrong!", true);
        }
      })
      .catch((error) => {
        fnShowSnackBar(error?.error || "Something is wrong!", true);
        console.log(error);
      });
  };

  const handleDraft = async () => {
    const { receiver, subject, body } = data;
    const modifyData = {
      ...data,
      receiver: receiver?.[0],
      attachments: selectedFiles.length > 0 ? selectedFiles : [],
    };
    if (body || subject || receiver?.length > 0) {
      try {
        addDraft(modifyData)
          .unwrap()
          .then((response) => {
            if (response?.success) {
              fnShowSnackBar(response?.message || "Email saved in Draft");
            } else {
              fnShowSnackBar("Some thing is wrong!", true);
            }
          });
      } catch (error) {
        fnShowSnackBar("Some thing is wrong!", true);
        console.error("An error occurred while saving:", error);
      }
    }
  };

  const handleAddReceiver = () => {
    if (emailRegex.test(receiverCurrentValue)) {
      setData((prevState) => ({
        ...prevState,
        receiver: [...prevState.receiver, receiverCurrentValue],
      }));
      setReceiverCurrentValue("");
    } else {
      fnShowSnackBar("Invalid Email!");
    }
  };

  const handleAddReceiverOnEnter = (e) => {
    if (e.code === "Enter") handleAddReceiver();
  };

  const handleAddReceiverOnBlur = () => {
    if (receiverCurrentValue) handleAddReceiver();
  };

  const removeFromList = (index) => {
    setData((prevState) => ({
      ...prevState,
      receiver: prevState?.receiver?.filter((_, i) => i !== index),
    }));
  };
  const handleAttachFile = (e) => {
    setSelectedFiles([...selectedFiles, ...e.target.files]);
  };
  const handleRemoveFile = (index) => {
    setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
  };
  return {
    handleAddReceiver,
    handleAddReceiverOnEnter,
    handleAddReceiverOnBlur,
    removeFromList,
    handleAttachFile,
    handleRemoveFile,
    selectedFiles,
    receiverCurrentValue,
    setReceiverCurrentValue,

    defaultToMail,
    isLoadingSendMail,
    composeModel,
    openComposeModel,
    fullComposeModel,
    setComposeModel,
    setFullComposeModel,
    setOpenComposeModel,
    data,

    handleSetMail,
    handleOpenComposeModel,
    handleDraftOpenComposeModel,
    handleInputChange,
    handleCancel,
    handleSubmit,
    setData,
    handleDraft,
    handleCLose,
  };
}

export default useComposeModel;
