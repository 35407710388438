import React from "react";

import './privacy.css'
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const Faqs = () => {

  const location = useLocation();

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[location.pathname]);

  return (
    <div className="flex items-center w-full min-h-full justify-between px-[6vw] py-[64px] gap-[24px] max-[1004px]:flex-col">
      <div className="privacy__page">
        <div className="inner__div">
          <div className="inner__container">
            <h3 className="normal__heading">Frequently Asked Questions (FAQ)</h3>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">What is Jurri?</h3>
            <p>
                Jurri is an email client application that allows you to manage multiple email accounts in one place.
            </p>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Which email providers does Jurri support?</h3>
            <p>
                Jurri currently supports Gmail, Outlook, and other major email providers.
            </p>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">How do I add a new email account?</h3>
            <p>
                Open the app, go to Settings, click "Add Account", and follow the prompts.
            </p>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Can I use Jurri with multiple email accounts?</h3>
            <p>
                Yes, Jurri supports multiple email accounts from various providers.
            </p>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Is my data secure with Jurri?</h3>
            <p>
                Yes, we use industry-standard encryption and security practices. See our Security page for details.
            </p>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Does Jurri store my emails?</h3>
            <p>
                Jurri caches emails locally on your device for offline access, but we don't store your emails on our servers.
            </p>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Why can't I send emails?</h3>
            <p>
                Check your internet connection and ensure your email account credentials are correct.
            </p>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">How do I report a bug?</h3>
            <p>
                Please email us at support@danzeetech.com with details about the issue.
            </p>
          </div>
          <div className="inner__container">
            <p>
                For more information, please check our User Guide or contact our support team.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
