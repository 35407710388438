import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Config } from '../constants/Index';
import { getLocalStorage } from '../components/localStorage';

const TAG_TYPES = { login: 'login', payment: 'payment', personal_info: 'personal_info', ids: 'ids' };

export const passwordManagerApi = createApi({
    reducerPath: 'passwordManagerApi',
    baseQuery: fetchBaseQuery({
        baseUrl: Config.serverApiUrl,
        prepareHeaders: async (headers, { getState, endpoint }) => {
            headers.set('email_id', getLocalStorage("emailId"));
            headers.set('email', getLocalStorage("email"));
            const storedToken = localStorage.getItem(Config.userToken);
            if (storedToken && endpoint !== 'refresh') headers.set('Authorization', `Bearer ${storedToken}`);
            return headers;
        },
    }),
    tagTypes: [TAG_TYPES.login, TAG_TYPES.payment, TAG_TYPES.personal_info ,TAG_TYPES.ids],
    endpoints: (builder) => ({
        // Logins API
        getLogins: builder.query({ query: () => 'manager/logins', transformResponse:(response)=> response?.data?.logins , providesTags: () => [TAG_TYPES.login] }),
        createLogin: builder.mutation({ query: (data) => ({ url: 'manager/logins', method: 'POST', body: data }), invalidatesTags: [TAG_TYPES.login] }),
        updateLogin: builder.mutation({ query: (data) => ({ url: `manager/logins/${data.id}`, method: 'PUT', body: data }), invalidatesTags: [TAG_TYPES.login] }),
        deleteLogin: builder.mutation({ query: (loginId) => ({ url: `manager/logins/${loginId}`, method: 'DELETE' }), invalidatesTags: [TAG_TYPES.login] }),
        deleteMultipleLogin : builder.mutation({ query: (data) => ({ url: `manager/delete-multiple-login`, method: 'POST', body : data }), invalidatesTags: [TAG_TYPES.login] }),

        // Payments API`
        getPayments: builder.query({ query: () => 'manager/payments', transformResponse:(response)=> response?.data?.payments, providesTags: () => [TAG_TYPES.payment] }),
        createPayment: builder.mutation({ query: (data) => ({ url: 'manager/payments', method: 'POST', body: data }), invalidatesTags: [TAG_TYPES.payment] }),
        updatePayment: builder.mutation({ query: (data) => ({ url: `manager/payments/${data?.id}`, method: 'PUT', body: data }), invalidatesTags: [TAG_TYPES.payment] }),
        deletePayment: builder.mutation({ query: (paymentId) => ({ url: `manager/payments/${paymentId}`, method: 'DELETE' }), invalidatesTags: [TAG_TYPES.payment] }),
        deleteMultiplePayment : builder.mutation({ query: (data) => ({ url: `manager/delete-multiple-payment`, method: 'POST', body : data }), invalidatesTags: [TAG_TYPES.payment] }),

        // Personal Info API
        getPersonalInfo: builder.query({ query: () => 'manager/personal_info', transformResponse:(response)=> response?.data?.personals, providesTags: () => [TAG_TYPES.personal_info] }),
        createPersonalInfo: builder.mutation({ query: (data) => ({ url: 'manager/personal_info', method: 'POST', body: data }), invalidatesTags: [TAG_TYPES.personal_info] }),
        updatePersonalInfo: builder.mutation({ query: (data) => ({ url: `manager/personal_info/${data.id}`, method: 'PUT', body: data }) }),
        deletePersonalInfo: builder.mutation({ query: (infoId) => ({ url: `manager/personal_info/${infoId}`, method: 'DELETE' }), invalidatesTags: [TAG_TYPES.personal_info],  }),

        // IDs API
        getIds: builder.query({ query: () => 'manager/ids', transformResponse:(response)=> response?.data?.ids, providesTags: () => [TAG_TYPES.ids] }),
        createId: builder.mutation({ query: (data) => ({ url: 'manager/ids', method: 'POST', body: data }), invalidatesTags: [TAG_TYPES.ids] }),
        updateId: builder.mutation({query: ({ id, ...data }) => ({ url: `manager/update-id/${id}`, method: 'POST', body: data.data,}),invalidatesTags: [TAG_TYPES.ids],}),
        // updateId: builder.mutation({  query: ({ id, ...data }) => ({    url: `manager/update-id/${id}`,    method: 'POST',    body: data,  }),  invalidatesTags: [TAG_TYPES.ids],}),
        deleteId: builder.mutation({ query: (id) => ({ url: `manager/ids/${id}`, method: 'DELETE' }), invalidatesTags: [TAG_TYPES.ids] }),
        deleteMultipleIds: builder.mutation({ query: (data) => ({ url: 'manager/delete-multiple-id', method: 'POST', body: data }), invalidatesTags: [TAG_TYPES.ids] }),
  

    }),
});

export const {
    useGetLoginsQuery,
    useCreateLoginMutation,
    useUpdateLoginMutation,
    useDeleteLoginMutation,
    useDeleteMultipleLoginMutation,

    useGetPaymentsQuery,
    useCreatePaymentMutation,
    useUpdatePaymentMutation,
    useDeletePaymentMutation,
    useDeleteMultiplePaymentMutation,

    useGetPersonalInfoQuery,
    useCreatePersonalInfoMutation,
    useUpdatePersonalInfoMutation,
    useDeletePersonalInfoMutation,

    useGetIdsQuery,
    useCreateIdMutation,
    useUpdateIdMutation,
    // useDeleteIdMutation,
    useDeleteMultipleIdsMutation,
} = passwordManagerApi;
