import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "ConditionalModelReducer",
  initialState: {
    isVisible: false,
    okCallback: null,
    cancelCallback: null,
    title: "Are you sure?",
    message: "",
    isLoading: false,
    okName: "Yes",
    cancelName: "No",
  },
  reducers: {
    showModal: (state, action) => {
      const {
        title,
        message,
        okCallback,
        cancelCallback,
        okName,
        cancelName,
        isLoading,
      } = action.payload;
      state.isVisible = true;
      state.title = title || "Are you sure?";
      state.message = message || "";
      state.okCallback = okCallback;
      state.cancelCallback = cancelCallback;
      state.okName = okName || "Yes";
      state.cancelName = cancelName || "No";
      state.isLoading = isLoading || false;
    },
    hideModal: (state) => {
      state.isVisible = false;
    },
  },
});

export const { showModal, hideModal } = slice.actions;

export const selectConditionalModal = (state) => state.ConditionalModelReducer;

export default slice.reducer;
