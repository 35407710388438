import React, { useRef, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { resetDragState, setDragDrop } from "../../../redux/DropZoneReducer";
import "./uploadData.css";
import UploadBox from "../../juriDrive/uploadBox/UploadBox";
import { useSnackBarManager } from "../../../lib/customHooks/useSnackBarManager";
import { useAddDriveFileMutation } from "../../../redux/juriiDriveApis";
import { useLocation } from "react-router-dom";
import useOutsideClick from "../../../lib/useOutsideClick";
import useKeyPress from "../../../lib/useKeyPress";
import { getLocalStorage } from "../../localStorage";
import { KEYS } from "../../../constants/Index";
import useGetPathUrl from "../../../lib/useGetPathUrl";

function UploadData({ id }) {
  const dispatch = useDispatch();
  const { fnShowSnackBar } = useSnackBarManager();
  const [addDriveFile] = useAddDriveFileMutation();
  const location = useLocation();
  const uploadDataRef = useRef(null);
  const [showFiles, setShowFiles] = useState([]);
  useOutsideClick(uploadDataRef, () => dispatch(setDragDrop()));
  useKeyPress("Escape", () => dispatch(resetDragState()));
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      const filesWithPreviewAndLoading = acceptedFiles.map((file) => ({
        ...file,
        preview: URL.createObjectURL(file),
        loading: true,
      }));
      setShowFiles(filesWithPreviewAndLoading);
      handleFileDrop(acceptedFiles);
    },
  });
  const { pathForFile } = useGetPathUrl();

  useEffect(() => {
    return () => {
      showFiles.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [showFiles]);

  const handleFileDrop = async (files) => {
    try {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`files[${i}]`, files[i]);
      }
      formData.append("parent_folder", pathForFile || "");
      await addDriveFile(formData);

      setShowFiles((prevFiles) =>
        prevFiles.map((file) => ({ ...file, loading: false }))
      );

      fnShowSnackBar("Files uploaded successfully!");
    } catch (error) {
      console.error("Error uploading file(s):", error);
      fnShowSnackBar("There was an error uploading the file(s).");
      setShowFiles((prevFiles) =>
        prevFiles.map((file) => ({ ...file, loading: false }))
      );
    }
  };

  return (
    <>
      <div
        id={id}
        {...getRootProps()}
        className={`upload-container ${isDragActive ? "active" : ""}`}
      >
        <input {...getInputProps()} />
        <UploadBox showFiles={showFiles} close={() => dispatch(setDragDrop())} />
      </div>
    </>
  );
}

export default UploadData;