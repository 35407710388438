import React, { useState } from "react";
import DZInput from "../../../components/shared/inputField/DZInput";
import DZBtn from "../../../components/shared/buttons/DZBtn";
import { Config } from "../../../constants/Index";
import NewPassword from "../newPassword/NewPassword";

const VerifyEmail = ({ email }) => {
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [codeConfirmation, setCodeConfirmation] = useState(false);

  const handleInputChange = (e) => {
    setVerificationCode(e.target.value);
  };

  const handleVerifyEmail = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${Config.serverUrl}api/verify-email-forgot-password`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, verification_code: verificationCode }),
        }
      );

      if (response.ok) {
        setMessage(
          "Email verified successfully. You can now reset your password."
        );
        setCodeConfirmation(true);
      } else {
        setMessage(
          "Verification failed. Please check your verification code and try again."
        );
      }
    } catch (error) {
      setMessage("Something went wrong. Please try again later.");
    }
    setLoading(false);
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      handleVerifyEmail();
    }
  };

  return (
    <>
      {codeConfirmation ? (
        <NewPassword email={email} verificationCode={verificationCode} />
      ) : (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Verify Email
            </h2>

            <p className="mt-4 text-center text-gray-600">
              A verification Code has been sent to {email}. Please check your
              email inbox
            </p>
            <div className="mt-6">
              <label
                htmlFor="verificationCode"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Verification Code
              </label>
              <div className="mt-2">
                <DZInput
                  id="verificationCode"
                  name="verificationCode"
                  type="text"
                  placeholder="Enter verification code"
                  value={verificationCode}
                  onChange={handleInputChange}
                  onKeyDown={handleEnterKey} // Added event handler for Enter key
                />
              </div>
            </div>
            <div className="mt-6 flex justify-center">
              <DZBtn
                toolTipText={"Verify Email"}
                textStyle={"text-F9F7F7 cursor-pointer"}
                solid={true}
                toolTipPlacement={"bottom"}
                className={"cursor-pointer rounded-[10px] py-[12px] px-[24px] "}
                children={loading ? "Loading..." : "Verify Email"}
                onClick={handleVerifyEmail}
              />
            </div>

            {message && (
              <p className="mt-4 text-center text-red-500">{message}</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyEmail;
