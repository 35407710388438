import React from "react";
import { useSnackBarManager } from "./customHooks/useSnackBarManager";
import JSZip from "jszip";
import {
  useDownloadFileMutation,
  useGetDriveFolderQuery,
} from "../redux/juriiDriveApis";
import axios from "axios";
import { Config } from "../constants/Index";
function useDownloadFileFolder() {
  const { fnShowSnackBar } = useSnackBarManager();
  const {
    data: driveFolderData,
    isLoading,
    isError,
  } = useGetDriveFolderQuery();
  const [downloadFiles, { isLoading: isLoadingDownload }] =
    useDownloadFileMutation();
  const downloadFile = async (path) => {
    const correctUrl = path.replace(/\/{2,}/g, "/");
    downloadFiles({ path: correctUrl });
  };
  const downloadFolder = async (folderUrl) => {
    const correctUrl = folderUrl.replace(/\/{2,}/g, "/");
    try {
      const response = await axios.get(correctUrl);
      const files = response.data;
      const zip = new JSZip();
      await Promise.all(
        files.map(async (file) => {
          const fileResponse = await axios.get(file.url, {
            responseType: "blob",
          });
          zip.file(file.name, fileResponse.data);
        })
      );
      const content = await zip.generateAsync({ type: "blob" });
      const a = document.createElement("a");
      const url = window.URL.createObjectURL(content);
      a.href = url;
      a.download = "folder.zip";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading folder:", error);
    }
  };

  const handleDownload = async (url, cardType) => {
    if (cardType === "folder") {
      await downloadFolder(url);
    } else if (cardType === "file") {
      await downloadFile(url);
    }
  };

  const handleDownloadMultipleItem = (fileFolderPaths) => {
    if (fileFolderPaths.length > 0) {
      fileFolderPaths.forEach(async (fileFolderPath) => {
        await downloadFile(`${Config.baseCorsProductUrl}${fileFolderPath}`);
      });
    }
  };
  return {
    downloadFile,
    downloadFolder,
    handleDownload,
    isLoadingDownload,
    handleDownloadMultipleItem,
  };
}

export default useDownloadFileFolder;
