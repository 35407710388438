import React, { useState, useEffect } from "react";
import DZText from "../text/DZText";
import InputField from "../inputField/InputField";
import DZBtn from "../buttons/DZBtn";
import DZTextArea from "../inputField/DZTextArea";
import { useHandleModalManager } from "../../../lib/customHooks/useHandleModalManager";
import useEnterKeySubmit from "../../../lib/useEnterKeySubmit";
import {
  useCreateIdMutation,
  useUpdateIdMutation,
} from "../../../redux/passwordManagerApis";
import { useSnackBarManager } from "../../../lib/customHooks/useSnackBarManager";
import { ID_CARD_TYPES } from "../../../constants/Index";
import DZModal from "./DZModal";

const AddUpdateIdsModal = ({
  cancel,
  title = "ID Card",
  modal,
  setModal,
  isUpdate = false,
  idCardData,
  fnEmptyData,
  type: idCardType,
}) => {
  // const { modalRef } = useHandleModalManager({ modal, setModal });
  const { fnShowSnackBar } = useSnackBarManager();

  const [inputData, setInputData] = useState({
    name: "",
    number: "",
    type: idCardType ?? "",
    issue_date: "",
    expiry_date: "",
    place_of_issue: "",
    country: "",
    note: "",
    file: null,
  });

  useEffect(() => {
    if (isUpdate && idCardData) {
      setInputData({ ...idCardData });
    }
  }, [isUpdate, idCardData]);

  const [createId, { isLoading: isLoadingCreate }] = useCreateIdMutation();
  const [updateId, { isLoading: isLoadingUpdate }] = useUpdateIdMutation();

  const { Driver_License, ID_Card, PassPort, Social_Security } = ID_CARD_TYPES;

  const inputFieldsData = [
    {
      name: "name",
      placeholder: "Name*",
      types: [ID_Card, Social_Security, Driver_License, PassPort],
    },
    {
      name: "number",
      placeholder: "Number*",
      types: [ID_Card, Social_Security, Driver_License, PassPort],
    },
    {
      name: "issue_date",
      placeholder: "Issue Date*",
      type: "date",
      types: [ID_Card, Driver_License, PassPort],
    },
    {
      name: "expiry_date",
      placeholder: "Expiration Date*",
      type: "date",
      types: [ID_Card, Driver_License, PassPort],
    },
    {
      name: "country",
      placeholder: "Country*",
      types: [ID_Card, Social_Security, Driver_License, PassPort],
    },
    {
      name: "place_of_issue",
      placeholder: "Place of issue*",
      types: [PassPort],
    },
    { name: "file", type: "file", types: [ID_Card] },
  ];

  const requiredFields =
    idCardType == Driver_License
      ? ["name", "number", "issue_date", "expiry_date", "country"]
      : idCardType == PassPort
      ? [
          "name",
          "number",
          "issue_date",
          "expiry_date",
          "country",
          "place_of_issue",
        ]
      : idCardType == Social_Security
      ? ["name", "number", "country"]
      : ["name", "number", "issue_date", "expiry_date", "country"];

  const fnOnChange = (event) => {
    const { name, value, files } = event.target;
    setInputData((prev) => ({ ...prev, [name]: files ? files[0] : value }));
  };

  // const handleSubmit = () => {
  //   isUpdate ? fnUpdateCard() : fnAddCard();
  // };

  const handleSubmit = () => {
    if (isUpdate) {
      fnUpdateCard();
    } else {
      fnAddCard();
    }
  };

  const fnAddCard = () => {
    const body = new FormData();
    requiredFields?.forEach((field) => body?.append(field, inputData[field]));
    body.append("note", inputData?.note);
    body.append("type", inputData?.type);
    if (inputData?.file) {
      body.append("file", inputData?.file);
    }

    if (requiredFields?.every((field) => inputData[field])) {
      createId(body)
        .unwrap()
        .then(({ success }) => {
          if (success) {
            setModal(false);
            fnShowSnackBar(`${inputData?.type} Added!`);
            setInputData({
              name: "",
              number: "",
              issue_date: "",
              expiry_date: "",
              country: "",
              note: "",
              type: "ID_Card",
              file: null,
            });
          } else {
            fnShowSnackBar("Failed to add ID card. Please try again.");
          }
        })
        .catch(() => {
          fnShowSnackBar("Something went wrong, please try again!");
        });
    } else {
      fnShowSnackBar("Please fill in all required fields.");
    }
  };

  // const fnUpdateCard = async () => {
  //   try {
  //     const formData = new FormData();
  //     for (const [key, value] of Object.entries(inputData)) {
  //       formData.append(key, value);
  //     }
  //     formData.append("file", inputData?.file);

  //     const data = Object.fromEntries(formData.entries());
  //     const response = await updateId({ id: idCardData.id, data }).unwrap();
  //     const idCard = response.data.id;
  //     setModal(false);
  //     fnShowSnackBar("ID card updated successfully!");
  //     if (ok) ok(idCard);
  //   } catch (error) {
  //     fnShowSnackBar("Something went wrong, please try again!");
  const fnUpdateCard = () => {
    const body = new FormData();
    requiredFields?.forEach((field) => body?.append(field, inputData[field]));
    body.append("note", inputData?.note);
    body.append("type", inputData?.type);
    if (inputData?.file) {
      body.append("file", inputData?.file);
    }
  
    if (requiredFields?.every((field) => inputData[field])) {
      updateId({ id: idCardData.id, data: body })
        .unwrap()
        .then(({ success }) => {
          if (success) {
            setModal(false);
            fnShowSnackBar("ID Card Updated!");
            fnEmptyData(); // Call a function to reset the data after successful update
          } else {
            fnShowSnackBar("Failed to update ID card. Please try again.");
          }
        })
        .catch(() => {
          fnShowSnackBar("Something went wrong, please try again!");
        });
    } else {
      fnShowSnackBar("Please fill in all required fields.");
    }
  };

  const { handleKeyDown } = useEnterKeySubmit(handleSubmit);

  return (
    <DZModal>
      <DZText
        normal
        children={isUpdate ? `Update ${title}` : `Add ${title}`}
        className={"text-112D4E"}
      />

      {inputFieldsData.map((data, i) => {
        const checkOnTypes = data?.types?.some((x) => x == idCardType);
        return (
          <>
            {checkOnTypes && data.type == 'date' && <span className='text-start w-full text-[12px] relative top-[10px] left-[5px] font-semibold text-112D4E'> {data.placeholder} </span>}
            {checkOnTypes && (
              <InputField
                key={i}
                onKeyDown={handleKeyDown}
                name={data.name}
                value={data.type != "file" ? inputData[data.name] : undefined}
                onChange={fnOnChange}
                placeholder={data.placeholder}
                type={data?.type || "text"}
                className={styles.input}
              />
            )}
          </>
        );
      })}

      <DZTextArea
        onKeyDown={handleKeyDown}
        name="note"
        value={inputData.note}
        onChange={fnOnChange}
        className={styles.input}
      />

      <div className="flex items-center gap-[14px]">
        <DZBtn onClick={handleSubmit} solid className={styles.addBtn}>
          {isUpdate
            ? isLoadingUpdate
              ? "Loading..."
              : "Update"
            : isLoadingCreate
            ? "Loading..."
            : "Add"}
        </DZBtn>
        <DZBtn onClick={cancel} className={styles.cancelBtn}>
          Cancel
        </DZBtn>
      </div>
    </DZModal>
  );
};

const styles = {
  input: "w-full placeholder:text-3F72AF",
  addBtn: "text-white rounded-[10px] cursor-pointer min-w-[104px]",
  cancelBtn: "rounded-[10px] text-112D4E cursor-pointer bg-white shadow",
};

export default AddUpdateIdsModal;
