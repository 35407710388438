import React from "react";
import { useState } from "react";

function useMailDetailLogic() {
  const [mailDetailStatus, setMailDetailStatus] = useState(false);
  const [mailId, setMailId] = useState(0);
  const [openMailDetail, setOpenMailDetail] = useState(false);
  const handleMailDetail = (id, email) => {
    if (mailId == id && mailDetailStatus == true) {
      setMailDetailStatus(false);
      setMailId(null);
    } else {
      setMailId(id);
      setOpenMailDetail(email);
      setMailDetailStatus(
        mailId == id && mailDetailStatus == true ? false : true
      );
    }
  };
  return {
    mailDetailStatus,
    setMailDetailStatus,
    mailId, 
    setMailId,
    handleMailDetail,
    openMailDetail,
  };
}
export default useMailDetailLogic;
