import React, { useState } from "react";
import { useAddUserMutation } from "../../redux/storeApis";
import DZBtn from "../../components/shared/buttons/DZBtn";
import { useNavigate } from "react-router";
import { ROUTES } from "../../reactRoute/RouteConstants";
import DZText from "../../components/shared/text/DZText";
import InputField from "../../components/shared/inputField/InputField";
import GoogleLoginButton from "../login/GoogleLoginButton";
import { useSelector } from "react-redux";
import Modal from "../../components/shared/modals/Modal";
import { useSnackBarManager } from "../../lib/customHooks/useSnackBarManager";
import useEnterKeySubmit from "../../lib/useEnterKeySubmit";
const Signup = () => {
  const [addUser, { isLoading }] = useAddUserMutation();
  const navigate = useNavigate();
  const { fnShowSnackBar } = useSnackBarManager();
  const loginUser = useSelector((state) => state.UserReducer.loginUser);
  const [showModal, setShowModal] = useState(loginUser !== null);
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    create_user_account: 0,
  });
  const handleCloseModal = () => {
    navigate("/");
    setShowModal(false);
  };
  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(String(email).toLowerCase());
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleSignup = async (e) => {
    e.preventDefault();
    if (!data.name) {
      fnShowSnackBar("Please enter your name", true);
      return;
    }
    if (!data.email || !isValidEmail(data.email)) {
      fnShowSnackBar("Please add a valid email", true);
      return;
    }
    if (!data.password) {
      fnShowSnackBar("Please enter a password", true);
      return;
    }

    if (data.password !== data.password_confirmation) {
      fnShowSnackBar("Passwords don't match", true);
      return;
    }
    if (loginUser) {
      setShowModal(true);
      return;
    }
    try {
      const response = await addUser(data).unwrap();
      if (response.success) {
        return navigate(`${ROUTES.confirmCode}`, {
          state: {
            email: data.email,
            password: data.password,
            name: data.name,
          },
        });
      } else {
        // fnShowSnackBar(response.message, true);
        // console.error(response, "response");
      }
    } catch (error) {
      console.error("Sign Up failed:", error);
      fnShowSnackBar(error.data?.errors?.email?.[0], true);
    }
  };
  const fnOnClickCheckBox = (event) => {
    if (event.target.checked) {
      setData({ ...data, create_user_account: 1 });
    } else {
      setData({ ...data, create_user_account: 0 });
    }
  };

  const { handleKeyDown } = useEnterKeySubmit(handleSignup);

  return (
    <>
      {showModal && (
        <Modal
          isOpen={showModal}
          onClose={handleCloseModal}
          title="Oops, something went wrong"
        >
          <p>
            You are already logged in. Please logout to access the signup page.
          </p>
        </Modal>
      )}
      <div className="flex items-center w-full h-full forms-back justify-between px-[6vw] py-[64px]">
        <div className="flex flex-col gap-[24px] max-w-[460px]">
          <DZText big className={"text-F9F7F7"} children={"Jurii"} />
          <DZText
            normal
            className={"text-F9F7F7"}
            children={"Get Started Today for Exclusive Services"}
          />
          <DZText
            small
            className={"text-F9F7F7"}
            children={
              "Welcome to our platform! By signing up today, you gain access to a suite of premium services designed to streamline your digital life. Store your important files and memories securely in our cloud storage solution, ensuring they're accessible anytime, anywhere. Safeguard your online accounts with our cutting-edge password manager, offering robust encryption and seamless synchronization across devices. Plus, elevate your communication experience with our personalized email service, empowering you to stay connected with ease. Join us now to unlock a world of convenience and peace of mind."
            }
          />
        </div>
        <div className="flex flex-col gap-[32px] p-[32px] w-[540px] rounded-[24px] bg-[#FFF]">
          <DZText children={"Sign Up"} normal className={"text-112D4E"} />
          <div className="flex flex-col gap-[12px] w-full">
            <InputField
              placeholder={"Name"}
              className={"w-full shadow"}
              name="name"
              value={data?.name}
              onChange={handleInputChange}
              autoComplete={"new-password"}
              onKeyDown={handleKeyDown}
            />
            <InputField
              placeholder={"Email"}
              className={"w-full shadow"}
              value={data?.email}
              type={"email"}
              onChange={handleInputChange}
              autoComplete={"new-password"}
              name={"email"}
              onKeyDown={handleKeyDown}
            />
            <InputField
              placeholder={"Password"}
              className={"w-full shadow"}
              value={data?.password}
              type={"password"}
              onChange={handleInputChange}
              autoComplete={"new-password"}
              name={"password"}
              onKeyDown={handleKeyDown}
            />
            <InputField
              placeholder={"Confirm Password"}
              className={"w-full shadow"}
              value={data?.password_confirmation}
              type={"password"}
              onChange={handleInputChange}
              name={"password_confirmation"}
              autoComplete={"new-password"}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="pl-[10px] flex items-center">
            <input
              type="checkbox"
              onChange={(event) => fnOnClickCheckBox(event)}
            />
            <span className="px-[10px] text-[14px]">Create Account</span>
          </div>
          <DZBtn
            onClick={handleSignup}
            children={isLoading ? "Loading..." : "Sign Up"}
            className={"rounded-[10px] gap-[4px]"}
            textStyle={"text-[#F7F9F9]"}
            solid
          />
          <div className="flex items-center gap-[12px]">
            <div className="bg-[#DBE2EF80] h-[2px] w-full"></div>
            <DZText children={"Or"} small className={"text-112D4E"} />
            <div className="bg-[#DBE2EF80] h-[2px] w-full"></div>
          </div>
          <div className="flex items-center gap-[12px]">
            <GoogleLoginButton />
          </div>
          <span
            onClick={() => navigate(ROUTES.login)}
            className="flex items-center justify-center gap-[4px] m-auto cursor-pointer"
          >
            <DZText
              className={"cursor-pointer"}
              children={"Already have an account?"}
            />
            <DZText
              className={"underline cursor-pointer"}
              children={" Login Here"}
            />
          </span>
        </div>
      </div>
    </>
  );
};
export default Signup;
