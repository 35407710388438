import React, { useEffect, useState } from "react";
import { useSnackBarManager } from "./customHooks/useSnackBarManager";
import {
  useAddToStarMutation,
  useGetStarDataQuery,
  useRemoveFromStarMutation,
} from "../redux/juriiDriveApis";

function useHandleDriveStar(pathName, cardType) {
  const { fnShowSnackBar } = useSnackBarManager();
  const { data: favoritesData, isLoading: isFavoriteLoading } =
    useGetStarDataQuery();
  const [addToStarMutation, { isLoading: isStarAddLoading }] =
    useAddToStarMutation();
  const [removeFromStarMutation, { isLoading: isStarRemoveLoading }] =
    useRemoveFromStarMutation();
  const [alreadyFavId, setAlreadyFavId] = useState(null);
  const [isAlreadyFav, setIsAlreadyFav] = useState(false);
  const handleFavorites = () => {
    if (isAlreadyFav) {
      removeFromFavorites();
    } else {
      addToFavorites();
    }
  };

  const isStarred = () => {
    if (cardType == "file") {
      return favoritesData?.data?.files?.some((item) => item.name == pathName);
    } else if (cardType == "folder") {
      return favoritesData?.data?.folders?.some(
        (item) => item.name == pathName
      );
    }
  };
  const addToFavorites = () => {
    setIsAlreadyFav(true);
    addToStarMutation({ name: pathName, type: cardType })
      .unwrap()
      .then((response) => {
        fnShowSnackBar("Added to favorites");
      })
      .catch((error) => {
        fnShowSnackBar("Failed to add to favorites");
      });
  };
  const removeFromFavorites = () => {
    setIsAlreadyFav(false);
    removeFromStarMutation(alreadyFavId)
      .unwrap()
      .then((response) => {
        fnShowSnackBar("Removed from favorites");
      })
      .catch((error) => {
        fnShowSnackBar("Failed to remove from favorites");
      });
  };
  useEffect(() => {
    setIsAlreadyFav(isStarred());
  }, [favoritesData]);
  useEffect(() => {
    if (cardType == "file") {
      setAlreadyFavId(
        favoritesData?.data?.files?.find((item) => item.name == pathName)?.id
      );
    } else if (cardType == "folder") {
      setAlreadyFavId(
        favoritesData?.data?.folders?.find((item) => item.name == pathName)?.id
      );
    }
  }, [favoritesData]);

  const isFavLoading =
    isStarAddLoading || isStarRemoveLoading ;
  return {
    handleFavorites,
    isFavLoading,
    isStarred,
    favoritesData,
    isAlreadyFav,
    alreadyFavId,
  };
}

export default useHandleDriveStar;
