import React, { useEffect } from "react";

import './privacy.css'
import { useLocation } from "react-router-dom";

const Privacy = () => {

  const location = useLocation();

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[location.pathname]);

  return (
    <div className="flex items-center w-full min-h-full justify-between px-[6vw] py-[64px] gap-[24px] max-[1004px]:flex-col">
    <div className="privacy__page">
      <div className="inner__div">
        <div className="inner__container">
          <h3 className="normal__heading">Privacy Policy for Email Client</h3>
        </div>
        <div className="inner__container">
          <h3 className="small__heading">Introduction</h3>
          <p>
            This Privacy Policy outlines the practices of our email client
            application, which allows users to access their Gmail and other Google
            and Microsoft services. We are committed to protecting your privacy
            and ensuring the security of your personal information. By using our
            application, you agree to the collection, use, and disclosure of your
            information as described in this Privacy Policy.
          </p>
        </div>
        <div className="inner__container">
          <h3 className="small__heading">Information Collection and Use</h3>
          <p>
            We collect various types of information in connection with the services
            we provide, including:
          </p>
          <ul>
            <li>
              <strong>Personal Information:</strong> When you use our application,
              we may collect personal information such as your name, email
              address, and login credentials for Google and Microsoft services.
              This information is used to authenticate your identity and grant you
              access to your email accounts.
            </li>
            <li>
              <strong>Email Data:</strong> Our application accesses your email data,
              including the contents of your emails, attachments, contact lists,
              and calendar events. This information is used solely to provide you
              with the functionality of the email client, such as reading, sending,
              and organizing emails.
            </li>
            <li>
              <strong>Usage Information:</strong> We collect information about how
              you use our application, including the features you use, the time and
              date of your activities, and other usage statistics. This information
              helps us improve our services and enhance your user experience.
            </li>
          </ul>
        </div>
        <div className="inner__container">
          <h3 className="small__heading">Data Security</h3>
          <p>
            We implement a variety of security measures to protect your personal
            information and email data. These measures include encryption, secure
            access controls, and regular security assessments. However, please note
            that no method of transmission over the internet or electronic storage
            is 100% secure, and we cannot guarantee absolute security.
          </p>
        </div>
        <div className="inner__container">
          <h3 className="small__heading">Data Sharing and Disclosure</h3>
          <p>
            We do not sell, trade, or otherwise transfer your personal information
            to outside parties without your consent, except as described in this
            Privacy Policy. We may share your information with trusted third
            parties who assist us in operating our application, conducting our
            business, or providing services to you, so long as those parties agree
            to keep this information confidential.
          </p>
          <p>
            <strong>Legal Requirements:</strong> We may disclose your personal
            information if required to do so by law or in response to valid requests
            by public authorities (e.g., a court or government agency).
          </p>
        </div>
        <div className="inner__container">
          <h3 className="small__heading">Third-Party Services</h3>
          <p>
            Our application integrates with third-party services such as Google and
            Microsoft. These services have their own privacy policies, and we
            encourage you to review them. We are not responsible for the privacy
            practices of these third-party services.
          </p>
        </div>
        <div className="inner__container">
          <h3 className="small__heading">Cookies and Tracking Technologies</h3>
          <p>
            We use cookies and similar tracking technologies to enhance your
            experience with our application. Cookies are small files that a site or
            its service provider transfers to your computer's hard drive through
            your web browser (if you allow) that enables the site's or service
            provider's systems to recognize your browser and capture and remember
            certain information.
          </p>
        </div>
        <div className="inner__container">
          <h3 className="small__heading">Your Rights</h3>
          <p>
            You have the right to access, correct, or delete your personal
            information that we hold. You can do this by contacting us through the
            contact information provided in the application. We will respond to
            your request within a reasonable timeframe.
          </p>
        </div>
        <div className="inner__container">
          <h3 className="small__heading">Changes to This Privacy Policy</h3>
          <p>
            We may update our Privacy Policy from time to time. We will notify you
            of any changes by posting the new Privacy Policy on this page. You are
            advised to review this Privacy Policy periodically for any changes.
          </p>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Privacy;
