import React, { useState, useEffect } from "react";
import sanitizeHtml from "sanitize-html";
import DZText from "../text/DZText";
import DZBtn from "../buttons/DZBtn";
import {
  ReplyIcon,
  CrossIcon,
} from "../../../icons/generalIcons/Icons";
import { useDispatch } from "react-redux";
import useComposeModel from "../../../lib/customHooks/useComposeModel";

const MailDetail = ({ receiver, subject, body, onClose, date }) => {
  const [isStarred, setIsStarred] = useState(false);
  const [sanitizedBody, setSanitizedBody] = useState("");
  const dispatch = useDispatch();
  const { handleOpenComposeModel } = useComposeModel();

  useEffect(() => {
    console.log("Raw body:", body); // Log the raw HTML content
    const cleanBody = sanitizeHtml(body || "", {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img", "p", "a", "span", "div"]),
      allowedAttributes: {
        "*": ["style", "class", "href", "target"],
        img: ["src"],
      },
      allowedSchemes: ["http", "https", "mailto"],
    });
    console.log("Sanitized body:", cleanBody); // Log the sanitized HTML content
    setSanitizedBody(cleanBody);
  }, [body]);

  const toggleStarred = () => {
    setIsStarred(!isStarred);
  };

  const handleReplay = () => {
    handleOpenComposeModel([receiver]);
  };

  return (
    <div className="flex flex-col align-start gap-[32px] p-[24px] rounded-[10px] bg-F9F7F7 h-full w-full overflow-y-scroll max-lg:fixed max-lg:left-0 max-lg:bottom-0 max-lg:z-10 max-lg:max-h-[88svh] max-lg:shadow-[0_35px_60px_1005px_#0A264760] max-lg:rounded-bl-[0] max-lg:rounded-br-[0]">
      <div className="flex items-center h-fit w-full justify-between gap-3 max-sm:flex-col max-sm:items-start">
        <div className="flex items-center gap-[20px] max-sm:w-full">
          <img
            src="https://picsum.photos/300"
            className="rounded-full max-w-[56px] max-h-[56px]"
            alt=""
          />
          <DZText
            className="text-112D4E text-[16px] truncate max-w-[170px] min-w-[140px]"
            children={receiver}
          />
          <span onClick={onClose} className="w-full flex items-end justify-end sm:hidden cursor-pointer">
            <CrossIcon />
          </span>
        </div>
        <div className="flex items-center gap-[20px]">
          <DZBtn
            onClick={() => handleReplay()}
            toolTipText={"Click to Reply"}
            simpleIcon
            className={"cursor-pointer shadow"}
            children={"Reply"}
            btnIcon={
              <ReplyIcon
                className={"cursor-pointer w-[20px] h-[20px]"}
                iconColor="#112D4E"
              />
            }
            textStyle={"cursor-pointer text-112D4E"}
          />
        </div>
        <span
          onClick={onClose}
          className="w-full flex items-end cursor-pointer justify-end max-sm:hidden lg:hidden"
        >
          <CrossIcon />
        </span>
      </div>
      <div className="flex flex-col gap-[24px]">
        <div className="flex flex-col gap-[4px]">
          <DZText className={"text-112D4E"} children={"Sender"} />
          <div
            children={receiver || " "}
            className={
              " bg-[#FFF] cursor-arrow disabled:bg-white placeholder:capitalize text-3F72AF px-[20px] py-[14px] cursor-text outline-none rounded-[10px] placeholder:text-[#3F72AF]"
            }
          />
        </div>
        <div className="flex flex-col gap-[4px]">
          <DZText
            type={"email"}
            className={"text-112D4E"}
            children={"Subject"}
          />
          <div
            children={subject}
            className={
              "bg-[#FFF] cursor-arrow disabled:bg-white placeholder:capitalize text-3F72AF px-[20px] py-[14px] cursor-text outline-none rounded-[10px] placeholder:text-[#3F72AF]"
            }
          />
        </div>
        <div className="flex flex-col gap-[4px]">
          <DZText
            className={"text-112D4E disabled:bg-white"}
            children={"Message"}
          />
          <div
            className="bg-[#FFF] lg:mb-[80px] break-words placeholder:capitalize text-3F72AF cursor-text cursor-arrow sentMail__detailTextArea text-3F72AF px-[20px] min-h-[400px] py-[14px] outline-none rounded-[10px] placeholder:text-[#3F72AF]"
            dangerouslySetInnerHTML={{ __html: sanitizedBody }}
          />
        </div>
      </div>
    </div>
  );
};

export default MailDetail;
