import React, { useEffect, useState, useSyncExternalStore } from "react";
import useAllAccountData from "../../lib/useAllAccountData";
import { DeleteIcon } from "../../icons/generalIcons/Icons";
import DeleteAccounts from "../../components/shared/modals/DeleteAccounts";
import { useDeleteUserAccountsMutation } from "../../redux/storeApis";
import { useSnackBarManager } from "../../lib/customHooks/useSnackBarManager";
import PP2Service from "../../services";
import { getLocalStorage } from "../../components/localStorage";
import { Config } from "../../constants/Index";

function Accounts() {
  const { accountsData, selectedEmail, handleSelectedEmail, refetchAllAccounts, isLoadingAllAccounts,  } = useAllAccountData();
  const [deleteUserAccount , { isLoading : isLoadingDeleteAccount }] = useDeleteUserAccountsMutation();

  const { fnShowSnackBar } = useSnackBarManager();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState(null);
  const [deleteLoader, setDeleteLoader]= useState(false);

  const handleDeleteAccount = (emailId, e) => {
    e.stopPropagation();
    setEmailToDelete(emailId);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    setDeleteLoader(true);
    const fetchUserToken = getLocalStorage(Config.userToken);

    try {
      const response = await deleteUserAccount(emailToDelete);
      if (response?.data?.success) {
        await refetchAllAccounts()?.unwrap()?.then(async()=> {

          const accounts = await PP2Service.fetch({url : 'user-accounts', token : fetchUserToken });
          const firstAccount = accounts?.data?.accounts?.[0];
          handleSelectedEmail({emailId : firstAccount?.id, email : firstAccount?.email, navigateToInbox : true});
          setDeleteLoader(false);
        })
      }
    } catch (error) {

      fnShowSnackBar('There is some error in deleting the account, please try again!');

    } finally {
      setShowDeleteModal(false);
      setEmailToDelete(null);
    }
  };

  return (
    <div className="flex flex-col gap-[20px] p-[30px] rounded-[20px] bg-[#F9F7F7]">
      <h1 className="text-[36px] font-[600] text-[#112D4E]">Accounts</h1>
      <p className="text-[#3F72AF]">
        On this page, you can seamlessly manage all your accounts with ease.
        Each account is displayed with its corresponding details, providing you
        with a comprehensive overview at a glance. Navigate through your
        accounts effortlessly, ensuring all your information is up-to-date and
        secure.
      </p>
      {isLoadingAllAccounts ? (
        <>Loading...</>
      ) : (
        accountsData?.map((account, index) => (
          <div
            key={index}
            onClick={() =>  handleSelectedEmail({ emailId : account?.id, email : account?.email, navigateToInbox : true})  }
            className={`flex items-center gap-[12px] rounded-[10px] cursor-pointer ${
              selectedEmail == account?.id
                ? "  bg-[#e5e8f0] px-[12px] py-[8px]  "
                : " "
            }`}
          >
            <img  src="https://fastly.picsum.photos/id/201/300/300.jpg?hmac=_rxkWLIRjtC0llnrLSRm7TvKgiQS_GquUeyXZnK9Lg8" alt=""  className="w-[56px] rounded-[100%] cursor-pointer" />
            <div className="flex flex-col gap-[2px] w-full cursor-pointer">
              <span className="text-[14px] text-[#112D4E] cursor-pointer">
                {account?.name}
              </span>
              <span className="text-[14px] text-[#3F72AF] cursor-pointer">
                {account?.email}
              </span>
            </div>
            <div
              onClick={(e) => handleDeleteAccount(account?.id, e)}
              className="p-2 bg-[#F9F7F7] rounded-full cursor-pointer"
            >
              <DeleteIcon className={"cursor-pointer"} />
            </div>
          </div>
        ))
      )}
      {showDeleteModal && (
        <DeleteAccounts
          yes={handleDelete}
          no={() => setShowDeleteModal(false)}
          okTitle={deleteLoader && 'Deleting...'}
          heading="Are you sure you want to delete this account?"
        />
      )}
    </div>
  );
}

export default Accounts;
