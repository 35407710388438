import React, { useState, useMemo } from "react";

function useFindFileType() {
  const [fileType, setFileType] = useState({
    isPdf: false,
    isImg: false,
    isVideo: false,
    isAudio: false,
    isDoc: false,
    isTxt: false, // Add isTxt to the state
  });

  const findFileType = useMemo(
    () => (url) => {
      const extension = url?.split(".").pop()?.toLowerCase();
      const isPdf = extension === "pdf";
      const isImg = /(png|jpg|jpeg|webp|svg|gif)$/i.test(extension);
      const isVideo = extension === "mp4";
      const isAudio = extension === "mp3";
      const isDoc = /(doc|docx)$/i.test(extension);
      const isTxt = extension === "txt"; // Check for txt files

      setFileType({
        isPdf,
        isImg,
        isVideo,
        isAudio,
        isDoc,
        isTxt, // Set isTxt state
      });
    },
    []
  );

  return { findFileType, ...fileType };
}

export default useFindFileType;
