import React, { useState } from 'react'
import DZText from '../text/DZText'
import InputField from '../inputField/InputField'
import DZBtn from '../buttons/DZBtn'
import DZTextArea from '../inputField/DZTextArea'
import { useSnackBarManager } from '../../../lib/customHooks/useSnackBarManager'
import { useCreateLoginMutation, useUpdateLoginMutation } from '../../../redux/passwordManagerApis'
import useEnterKeySubmit from '../../../lib/useEnterKeySubmit'
import { useDispatch } from 'react-redux'
import { hideSelectedLoginModal } from '../../../redux/PasswordManagerReducer'
import DZModal from './DZModal'

const AddUpdateLoginModal = ({ cancel, title = "Add a login", modal, setModal, isUpdate, loginData, fnEmptyData}) => {

    const dispatch = useDispatch();

    const [ createManagerLogin, { isLoading : isLoadingCreateManagerLogin } ] = useCreateLoginMutation();
    const [ updateManagerLogin, { isLoading : isLoadingUpdateManagerLogin } ] = useUpdateLoginMutation();

    const { fnShowSnackBar } = useSnackBarManager();

    const [inputData, setInputData] = useState({ website : loginData?.website??'', email: loginData?.email??'', password:loginData?.password??'', note : loginData?.note??'' });

    const fnOnChange =(event)=>{
        const { name, value } = event.target;
        setInputData(pre => ({ ...pre, [name]: value }));
    };

    const fnAddLogin =()=>{

      const data = { website: inputData.website, email : inputData.email, password: inputData.password, note: inputData.note };

      if(inputData.website && inputData.email && inputData.password){
        createManagerLogin(data).unwrap().then((payload) => {
          if (payload.success) {
              setModal(false);
              dispatch( hideSelectedLoginModal());
              fnShowSnackBar('Login Added!');
              setInputData(null);
          }
        }
        ).catch((error) => {
            // fnShowSnackBar('Something went wrong, please try agian!')
        });
      } else {
        fnShowSnackBar('Please fill the required fields!');
      };

    };

    const fnUpdateLogin =()=>{

      const data = { id: loginData?.id, website: inputData.website, email : inputData.email, password: inputData.password, note: inputData.note };

      if(inputData.website && inputData.email && inputData.password){
        updateManagerLogin(data).unwrap().then((payload) => {
          if (payload?.success) {
              setModal(false);
              dispatch( hideSelectedLoginModal());
              fnShowSnackBar('Updated!');
              setInputData(null);
              if(fnEmptyData) { fnEmptyData();}
          }
        }
        ).catch((error) => {
            fnShowSnackBar('Something went wrong, please try agian!')
        });
      } else {
        fnShowSnackBar('Please fill the required fields!');
      };

    };
    const {handleKeyDown} = useEnterKeySubmit(isUpdate ? fnUpdateLogin : fnAddLogin);
  return (
    <DZModal>
      <DZText normal children={isUpdate ? 'Update a login' : title} className={"text-112D4E"} />

      <InputField name={'website'} onKeyDown={handleKeyDown} value={inputData?.website} onChange={fnOnChange} placeholder="Website URL*" className={styles.input} />
      <InputField name={'email'} value={inputData?.email} onKeyDown={handleKeyDown} onChange={fnOnChange}  placeholder="Email*" className={styles.input} />
      <InputField name={'password'} value={inputData?.password} onKeyDown={handleKeyDown} onChange={fnOnChange} placeholder="Password*" className={styles.input} type={'password'} />

      <DZTextArea name={'note'} onKeyDown={handleKeyDown} value={inputData?.note} onChange={fnOnChange} className={styles.input} />

      <div className="flex items-center gap-[14px]">
        <DZBtn onClick={()=> { isUpdate ? fnUpdateLogin() : fnAddLogin()}} solid className={styles.addBtn}>
            { isUpdate ? (isLoadingUpdateManagerLogin ? 'Loading...' : 'Update') : (isLoadingCreateManagerLogin ? 'Loading...' : 'Add') }
        </DZBtn>
        <DZBtn onClick={cancel} className={styles.cancelBtn}>
            {'Cancel'}
        </DZBtn>
      </div>
    </DZModal>
  )
}
const styles = {
    input:'w-full placeholder:text-3F72AF',
    addBtn:'text-white rounded-[10px] cursor-pointer min-w-[104px]',
    cancelBtn:'rounded-[10px] text-112D4E cursor-pointer bg-white shadow'
}

export default AddUpdateLoginModal