import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import { StarIcon, ClockIcon } from "../../../icons/generalIcons/Icons";
import CheckInput from "../checkInput/CheckInput";
import DZText from "../text/DZText";
import MobileMailCard from "../mobileMailCard/MobileMailCard";
import TimeDisplay from "../../ui/error/TimeDisplay";
import { useAddFavouritesMutation, useGetFavouritesQuery } from "../../../redux/storeApis";

const MailCard = ({ email, className, handleMailDetail, mailId }) => {

  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState(false);
  const [isStarred, setIsStarred] = useState(false);
  const [addFavourites] = useAddFavouritesMutation();
  const { data: favorites } = useGetFavouritesQuery();
  const toggleActive = () => setActive(!active);
  const toggleSelected = () => setSelected(!selected);
  const found = favorites?.data?.favourite_emails?.favourites?.find( (aEmail) => aEmail?.email?.id == email?.id );
  const isAlreadyAdded = found?.email_id == email?.id;

  const toggleStarred = async () => {
    setIsStarred(!isStarred);
    if (found) {
      try {
        await addFavourites({ id: found?.id, email_id: email.id });
      } catch (error) {
        console.error("Error toggling favorites:", error);
      }
    } else {
      try {
        await addFavourites({ id: null, email_id: email.id });
      } catch (error) {
        console.error("Error toggling favorites:", error);
      }
    }
  };

  return (
    <>
      <div
        onClick={() => {
          if (handleMailDetail) {
            handleMailDetail(email?.id, email);
          }
        }}
        className={` ${className} ${mailId == email?.id && "!bg-[#86BCFC30]"
          } flex items-center px-[24px] py-[12px] bg-F9F7F7 gap-[24px] w-full rounded-[10px] max-sm:hidden cursor-pointer `}
      >
        <Tooltip
          arrow
          title="Click to star it (you can see it in the starred page)"
        >
          <span
            className="cursor-pointer"
            onClick={(e) => {
              toggleStarred(e);
              e.stopPropagation();
            }}
          >
            <StarIcon
              iconColor="#3F72AF"
              pointer
              bgColor={`${isAlreadyAdded ? "#3F72AF" : ""}`}
            />
          </span>
        </Tooltip>
        <Tooltip arrow title="Click to select it">
          <span
            onClick={(e) => {
              toggleActive(e);
              e.stopPropagation();
            }}
            className="cursor-pointer"
          >
            <CheckInput active={active} />
          </span>
        </Tooltip>
        <div
          className="flex flex-col gap-[4px] max-w-full w-full cursor-pointer"
          onClick={toggleSelected}
        >
          <DZText
            children={email?.receiver || ""}
            small
            className={
              "text-112D4E w-fit max-w-[160px] truncate cursor-pointer"
            }
          />
          <DZText
            children={email?.subject || ""}
            small
            className={
              "text-112D4E w-fit max-w-[160px] truncate cursor-pointer"
            }
          />
        </div>
        <div className="flex items-center gap-[8px]" onClick={toggleSelected}>
          <ClockIcon iconColor="#112D4E" className={"cursor-pointer"} />
          <TimeDisplay timestamp={email?.created_at} />
        </div>
      </div>
      <MobileMailCard
        isStarred={isStarred}
        active={active}
        toggleStarred={toggleStarred}
        toggleActive={toggleActive}
        toggleSelected={toggleSelected}
        selected={selected}
      />
    </>
  );
};

export default MailCard;
