import React from "react";

import './privacy.css'
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const TermServices = () => {

  const location = useLocation();

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[location.pathname]);

  return (
    <div className="flex items-center w-full min-h-full justify-between px-[6vw] py-[64px] gap-[24px] max-[1004px]:flex-col">
      <div className="privacy__page">
        <div className="inner__div">
          <div className="inner__container">
            <h3 className="normal__heading">Terms of Service for Jurii</h3>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Introduction</h3>
            <p>
              These Terms of Service ("Terms") govern your use of our email client
              application ("Service"). By using our Service, you agree to be bound
              by these Terms. If you do not agree to these Terms, please do not use
              our Service.
            </p>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Use of Service</h3>
            <p>
              You are granted a limited, non-exclusive, non-transferable, revocable
              license to use the Service in accordance with these Terms. You agree
              to use the Service only for lawful purposes and in a manner that does
              not infringe the rights of, or restrict or inhibit the use and
              enjoyment of the Service by any third party.
            </p>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Account Security</h3>
            <p>
              You are responsible for maintaining the confidentiality of your login
              credentials and for all activities that occur under your account. You
              agree to notify us immediately of any unauthorized use of your
              account or any other breach of security.
            </p>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Prohibited Activities</h3>
            <p>
              You agree not to use the Service to:
            </p>
            <ul>
              <li>Violate any applicable laws or regulations.</li>
              <li>Infringe the rights of any third party, including but not limited to intellectual property rights, privacy rights, or proprietary rights.</li>
              <li>Distribute viruses or any other harmful computer code.</li>
              <li>Engage in any activity that could damage, disable, overburden, or impair the Service.</li>
            </ul>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Termination</h3>
            <p>
              We reserve the right to terminate or suspend your access to the
              Service at any time, without prior notice or liability, for any
              reason, including if you breach these Terms.
            </p>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Disclaimer of Warranties</h3>
            <p>
              The Service is provided on an "as is" and "as available" basis,
              without warranties of any kind, either express or implied, including,
              but not limited to, implied warranties of merchantability, fitness for
              a particular purpose, or non-infringement.
            </p>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Limitation of Liability</h3>
            <p>
              In no event shall we be liable for any indirect, incidental, special,
              consequential or punitive damages, or any loss of profits or revenues,
              whether incurred directly or indirectly, or any loss of data, use,
              goodwill, or other intangible losses, resulting from your use of the
              Service.
            </p>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Changes to Terms</h3>
            <p>
              We may update these Terms from time to time. We will notify you of any
              changes by posting the new Terms on this page. You are advised to
              review these Terms periodically for any changes.
            </p>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Governing Law</h3>
            <p>
              These Terms shall be governed and construed in accordance with the
              laws of [Your Country/State], without regard to its conflict of law
              provisions.
            </p>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Contact Us</h3>
            <p>
              If you have any questions about these Terms, please contact us at
              danzeetech@gmail.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermServices;
