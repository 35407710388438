import React from "react";

import './privacy.css'
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const ClientUserGuide = () => {

  const location = useLocation();

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[location.pathname]);

  return (
    <div className="flex items-center w-full min-h-full justify-between px-[6vw] py-[64px] gap-[24px] max-[1004px]:flex-col">
      <div className="privacy__page">
        <div className="inner__div">
          <div className="inner__container">
            <h3 className="normal__heading">Jurri Email Client User Guide</h3>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Getting Started</h3>
            <ul>
              <li>Download and install Jurri from our website or your device's app store.</li>
              <li>Open the app and click "Add Account".</li>
              <li>Choose your email provider (Gmail, Outlook, etc.) and follow the authentication steps.</li>
            </ul>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Basic Features</h3>
            <ul>
              <li>Inbox : View all your emails in one place.</li>
              <li>Compose : Create new emails by clicking the "+" button.</li>
              <li>Search : Use the search bar to find specific emails..</li>
            </ul>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Advanced Features</h3>
            <ul>
              <li>Labels and Folders : Organize your emails for easy access.</li>
              <li>Filers : Set up rules to automatically sort incoming emails.</li>
              <li>Multiple Accounts : Add and manage multiple email accounts.</li>
            </ul>
          </div>
          <div className="inner__container">
            <h3 className="small__heading">Troubleshooting</h3>
            <ul>
              <li>If you can't log in, ensure you've entered the correct credentials.</li>
              <li>For sync issues, check your internet connection and try refreshing.</li>
            </ul>
          </div>
          <div className="inner__container">
            <p>
                For more detailed instructions, please refer to our FAQ section or contact our support team.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientUserGuide;