import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "DriveFilterReducer",
  initialState: {
    itemTypeModel: "All",
  },
  reducers: {
    setItemTypeModel: (state, action) => {
      state.itemTypeModel = action.payload;
    },
  },
});

export const { setItemTypeModel } = slice.actions;

export const selectItemTypeModel = (state) =>
  state.DriveFilterReducer.itemTypeModel;

export default slice.reducer;
