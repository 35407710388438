import React from 'react'

const DZModal = ({children, modalRef}) => {
  return (
    <div className='fixed flex items-center justify-center bg-[#112D4E20] inset-0 z-10'>
        <div ref={modalRef} className={'z-[10] bg-F9F7F7 flex flex-col w-[420px] items-center justify-center gap-[16px] p-[24px] rounded-[12px]'}>
            {children}
        </div>
    </div>
  )
}

export default DZModal