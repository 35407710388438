import React from 'react'
import DZText from '../text/DZText';
import { Tooltip } from '@mui/material';

const  DZBtn = ({
  children,
  className,
  onClick,
  outline,
  solid,
  fullRadius,
  smallText,
  textStyle,
  btnIcon,
  toolTipText,
  simpleIcon,
  toolTipPlacement }) => { 
  return (
    <Tooltip
      title={toolTipText}
      arrow
      disableInteractive 
      placement={toolTipPlacement}>

      <div className={` cursor-pointer
      ${className} 
      ${outline && 'bg-DBE2EF'} 
      ${fullRadius && 'rounded-full'} 
      ${simpleIcon && 'bg-F9F7F7 rounded-[10px]'} 
      ${solid && "bg-112D4E text-[#f9f7f7]"}  gap-[12px] py-[12px] px-[24px] flex items-center justify-center cursor-pointer`}
        onClick={onClick}
      >

        {btnIcon}

        <DZText
          className={`${textStyle} cursor-pointer text-[14px]`}
          small={smallText}
          children={children}
        />

      </div>

    </Tooltip>
  )
}

export default DZBtn;