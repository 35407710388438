import React from "react";

const FileIcon = ({ className }) => {
  return (
    <img src="/File_Icon.png" className={className} alt="" />
  );
};

export default FileIcon;


