import React, { useEffect, useState } from "react";
import {
  useGetDriveFoldersQuery,
  useGetDriveFolderQuery,
  useMoveDriveFileMutation,
} from "../../../redux/juriiDriveApis";
import DZText from "../../shared/text/DZText";
import FolderIcon from "../../../icons/cardIcons/FolderIcon";
import DZBtn from "../../shared/buttons/DZBtn";
import ArrowDown from "../../../icons/arrowIcons/ArrowDown";
import { useDispatch } from "react-redux";
import {
  destinationSlugSelector,
  setDestinationSlug,
  sourceFileSelector,
} from "../../../redux/MoveReducer";
import { useSelector } from "react-redux";
const MoveModal = ({ setShowMoveModal }) => {
  const [driveFolders, setDriveFolders] = useState(null);
  const [folderSlug, setFolderSlug] = useState("");
  const sourceFile = useSelector(sourceFileSelector);
  const destinationSlug = useSelector(destinationSlugSelector);
  const dispatch = useDispatch();
  const { data: driveFoldersData, isLoading } = useGetDriveFoldersQuery();
  const { data: folderData, refetch } = useGetDriveFolderQuery(folderSlug);
  const [moveFile, { isLoading: isLoadingMoveFile }] =
    useMoveDriveFileMutation();

  const handleFolderDoubleClick = (folderName) => {
    const newFolderSlug = folderSlug
      ? `${folderSlug}/${folderName}`
      : folderName;
    setFolderSlug(newFolderSlug);
    dispatch(setDestinationSlug(newFolderSlug));
    refetch();
  };

  useEffect(() => {
    setDriveFolders(
      folderData ? folderData?.data?.folders : driveFoldersData?.data?.folders
    );
  }, [driveFoldersData, folderData]);
  function handleMove() {
    moveFile({ source_file: sourceFile, destination_file: destinationSlug });
    setShowMoveModal(false);
  }
  return (
    <div className="max-lg:min-w-[420px] max-sm:min-w-[320px] max-sm:p-[16px] min-w-[614px] flex flex-col gap-[16px] modal-shadow z-[10] bg-F9F7F7 items-center justify-center p-[24px] fixed top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%] rounded-[12px]">
      <DZText
        normal
        children={"Move this file/folder “XYZ 123”"}
        className={"text-112D4E text-center"}
      />

      <DZText
        small
        children={"Select a location where you want the file/folder to move"}
        className={"text-3F72AF text-center"}
      />

      <div className="flex items-center gap-[16px] cursor-pointer group w-full">
        <ArrowDown
          className={
            "rotate-90 w-[20px] h-[20px] cursor-pointer transition duration-500 group-hover:-translate-x-2"
          }
        />

        <DZText
          children={"Click to go back"}
          className={"text-112D4E cursor-pointer"}
        />
      </div>
      <div className="grid max-lg:grid-cols-1 grid-cols-2 gap-[16px] p-[16px] bg-white rounded-[10px] w-full max-h-[400px] overflow-y-scroll">
        {isLoading
          ? "Loading..."
          : driveFolders?.length > 0
          ? driveFolders?.map((folder, index) => (
              <div
                key={folder?.id}
                className="flex items-center gap-[12px] p-[12px] bg-DBE2EF rounded-[10px] cursor-pointer"
                onDoubleClick={(e) => {
                  e.stopPropagation();
                  handleFolderDoubleClick(folder?.name);
                }}
              >
                <FolderIcon
                  className={
                    "min-w-[46px] max-w-[46px] h-[46px] cursor-pointer"
                  }
                />
                <DZText
                  children={folder?.name}
                  small
                  className={
                    "text-3F72AF max-lg:w-[200px] truncate w-[160px] cursor-pointer text-white"
                  }
                />
              </div>
            ))
          : null}
      </div>
      <div className="flex items-center gap-[24px]">
        <DZBtn
          children={isLoadingMoveFile ? "Loading..." : "Move"}
          solid
          textStyle={"cursor-pointer text-F9F7F7"}
          className={"rounded-[10px]"}
          onClick={(e) => {
            handleMove();
            e.stopPropagation();
          }}
        />
        <DZBtn
          children={"Cancel"}
          outline
          textStyle={"cursor-pointer"}
          className={"rounded-[10px] bg-F9F7F7 shadow"}
          onClick={(e) => {
            setShowMoveModal(false);
            e.stopPropagation();
          }}
        />
      </div>
    </div>
  );
};

export default MoveModal;
