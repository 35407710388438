import React, { useEffect, useRef, useState } from 'react'
import PasswordManagerBar from '../passwordManagerBar/PasswordManagerBar';
import AddCreditCardModal from '../../components/shared/modals/AddCreditCardModal';
import SelectDropDown from '../../components/shared/dropDowns/SelectDropDown';
import ViewDropDownItem from '../../components/views/ViewDropDownItem';
import { BankIcon, ClockIcon, CreaditCardIcon, CrossIcon, DeleteIcon, EditIcon, SearchIcon } from '../../icons/generalIcons/Icons';
import { useHandleModalManager } from '../../lib/customHooks/useHandleModalManager';
import AddBankDetailsModal from '../../components/shared/modals/AddBankDetailsModal';
import { useDeleteMultiplePaymentMutation, useDeletePaymentMutation, useGetPaymentsQuery } from '../../redux/passwordManagerApis';
import { Tooltip } from '@mui/material';
import DZText from '../../components/shared/text/DZText';
import TimeDisplay from '../../components/ui/error/TimeDisplay';
import CheckInput from '../../components/shared/checkInput/CheckInput';
import { useSnackBarManager } from '../../lib/customHooks/useSnackBarManager';
import DeleteModal from '../../components/shared/modals/DeleteModal';
import { EmptyLoader, SimpleLoader } from '../../components/loader/Loaders';
import { useSearchManager } from '../../lib/customHooks/useSearchManager';
import DZBtn from '../../components/shared/buttons/DZBtn';
import { useSelector } from 'react-redux';
import { selectedPaymentModalSelector, setSelectedPaymentModal } from '../../redux/PasswordManagerReducer';
import { useDispatch } from 'react-redux';

const PasswordManagerPayments = () => {

  const dispatch = useDispatch();

  const { data: allPaymentsData, isLoading: isLoadingAllPaymentsData } = useGetPaymentsQuery();
  // const [deleteCard, { isLoading: isLoadingDeleteCard }] = useDeletePaymentMutation();
  const [ deleteMultiplePayments, {isLoading : isLoadingDeleteMultiplePayments} ] = useDeleteMultiplePaymentMutation();

  const { fnShowSnackBar } = useSnackBarManager();

  const isShowPaymentItemsModal = useSelector(selectedPaymentModalSelector);

  const [paymentModal, setPaymentModal] = useState(false);
  const [cardModal, setCardModal] = useState({ add: null, update: null });
  const [bankModal, setBankModal] = useState({ add: null, update: null });
  const [selectedCardDetail, setSelectedCardDetail] = useState(false);
  const [selectedBankDetail, setSelectedBankDetail] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [selectedPayments, setSelectedPayments] = useState([]);

  const { modalRef } = useHandleModalManager({ modal: paymentModal, setModal: setPaymentModal, closeModal : ()=> dispatch(setSelectedPaymentModal(false)) });
  const { searchLoader, fnOnSearchInputChange, fnOnSearch, fnOnEnter, fnFilterPayments } = useSearchManager();

  const filteredPayments = fnFilterPayments(allPaymentsData);
  const isExistPaymentsData = filteredPayments?.length > 0;
  const isExistSelectedPayments = selectedPayments?.length > 0;

  const fnDeleteCard = () => {
    const ids = selectedPayments?.map((payment)=> payment?.id);

    deleteMultiplePayments({ids}).unwrap().then((payload) => {
      if (payload?.success) {
        setSelectedCardDetail(false);
        setSelectedPayments([]);
        fnShowSnackBar('Deleted selected Items successfully!');
        setDeleteModal(false);
      }
    }
    ).catch((error) => {
      fnShowSnackBar('Something went wrong, please try agian!')
    });
  };

  const fnEmptyData = () => {
    setSelectedCardDetail(false);
    setSelectedBankDetail(false);
  };

  const fnClickOnEditIcon = (isCard, detail) => {
    if (isCard) {
      setSelectedCardDetail(detail);
      setCardModal({ ...cardModal, update: true });
    } else {
      setSelectedBankDetail(detail);
      setBankModal({ ...bankModal, update: true });
    }

  };

  const fnOnClickCheckInput =(e, detail)=> {
    e.stopPropagation();
    const isAlreadyExist = selectedPayments?.find((payment)=> payment?.id == detail?.id);
    if(isAlreadyExist) {
      const removedPayments = selectedPayments?.filter((payment)=> payment?.id != detail?.id);
      setSelectedPayments(removedPayments);
    } else  {
      setSelectedPayments([...selectedPayments, detail]);
    }
  };

  return (
    <div className="flex flex-wrap gap-[24px] h-full overflow-y-scroll last:pb-[56px]">

      {isShowPaymentItemsModal && <SelectDropDown dropDownRef={modalRef} className={'absolute top-[206px] shadow left-[16px] min-w-[302px] z-[2] max-lg:top-[80px] max-lg:left-[84px] max-sm:top-auto max-sm:bottom-[80px] max-sm:left-[8%]'}>
        <ViewDropDownItem title={'Credit/Debit Card'} onClick={() => { setPaymentModal(false); setCardModal({ ...cardModal, add: true }) }} icon={<CreaditCardIcon iconColor='#3F72AF' />} />
        <ViewDropDownItem title={'Bank Account'} onClick={() => { setPaymentModal(false); setBankModal({ ...bankModal, add: true }) }} icon={<BankIcon iconColor='#3F72AF' />} />
      </SelectDropDown>}

      <DZText className={'text-[#112D4E] w-full'} children={"Payments"} normal />

      <div className="w-full">
        <div className="flex items-center justify-between bg-[white] rounded-[25px] drop-shadow mx-1 px-[8px] w-full max-w-[600px]">
          <input
            type="text"
            placeholder="Search"
            className="text-[16px] w-[full] cursor-text p-[12px] rounded-[25px] ml-[5px] outline-none placeholder:text-[#112D4E] text-112D4E"
            onChange={(event) => fnOnSearchInputChange(event)}
            onKeyDown={fnOnEnter}
          />
          <DZBtn onClick={fnOnSearch} solid className={'rounded-full !px-[12px]'} textStyle={'hidden'} btnIcon={searchLoader ? <img className="rounded-full w-[16.5px] animate-spin" src={'/circle.png'} /> : <SearchIcon className="cursor-pointer" iconColor="#f9F7F7" />} />
        </div>
      </div>

      {isExistSelectedPayments && <div className={`flex items-center justify-between w-full h-[46px]`}>

        <div className="flex items-center gap-[12px]">
          <span onClick={() => setSelectedPayments([])} className="bg-[#112D4E] p-[6px] rounded-full cursor-pointer" >
            <CrossIcon
              iconColor="#F9F7F7"
              className={"w-[20px] h-[20px]  cursor-pointer"}
            />
          </span>
        </div>

        <div className="flex items-center gap-[20px]">

          <Tooltip arrow title="Delete" >
            <span onClick={() => setDeleteModal(true)} className="bg-[#F9F7F7] p-[10px] rounded-full cursor-pointer">
              <DeleteIcon className={"w-[16px] h-[16px]  cursor-pointer"} />
            </span>
          </Tooltip>

        </div>

        </div>
      }

      {
        isLoadingAllPaymentsData ? <SimpleLoader />
          : isExistPaymentsData ? filteredPayments?.map((detail) => {

            const isCard = detail?.type == 'card';

            return (
              <div className={`relative group flex rounded-[10px] flex-col gap-[16px] p-[12px] pt-[42px] w-[262px] bg-[#F9F7F7]`}>

                <div className="flex items-center justify-between">

                  <span className="text-[14px] text-[#3F72AF]">
                    {isCard ? "**** **** **** " + detail?.card_number?.slice(-4)?.replace(/(.{4})/g, "$1 ") : detail?.card_number}
                  </span>

                  {isCard ? <CreaditCardIcon /> : <BankIcon />}

                </div>

                <div className="flex flex-col w-full gap-[6px]">

                  <div className="flex items-center w-full justify-between">

                    <span className="text-[14px] text-[#3F72AF]">
                      {isCard ? 'Expiration Date :-' : 'Country :-'}
                    </span>

                    <span className="text-[14px] text-[#3F72AF]">
                      {isCard ? detail?.date : detail?.country}
                    </span>

                  </div>

                  <div className="flex items-center w-full justify-between">

                    <span className="text-[14px] text-[#3F72AF]">
                      Name :-
                    </span>

                    <span className="text-[14px] text-[#3F72AF]">
                      {detail?.name}
                    </span>

                  </div>

                  {isCard ? <div className="flex items-center w-full justify-between">

                    <span className="text-[14px] text-[#3F72AF]">
                      CVC :-
                    </span>

                    <span className="text-[14px] text-[#3F72AF]">
                      {detail?.cvc}
                    </span>

                  </div>
                    : <div className="flex items-center w-full justify-between">

                      <span className="text-[14px] text-[#3F72AF]">
                        Bank Name :-
                      </span>

                      <span className="text-[14px] text-[#3F72AF]">
                        {detail?.b_name}
                      </span>

                    </div>
                  }

                </div>

                <span onClick={(e) => fnOnClickCheckInput(e, detail)} >
                  <CheckInput active={selectedPayments?.some((payment)=> payment?.id == detail?.id)} className={'!absolute top-[10px] left-[10px]'} />
                </span>

                <div className="absolute flex z-1 items-center gap-[10px] right-[10px] top-[10px] opacity-0 group-hover:opacity-100 transition">


                  <DeleteIcon onClick={() => { setSelectedPayments([detail]); setDeleteModal(true)}} iconColor='#3F72AF' className={'cursor-pointer'} />
                  <EditIcon onClick={() => { fnClickOnEditIcon(isCard, detail) }} iconColor='#3F72AF' className={'cursor-pointer'} />

                </div>

              </div>
            )
          }) : <EmptyLoader />
      }
      {/* Credit card add and update modal */}
      {(cardModal.add || cardModal.update) && <AddCreditCardModal modal={cardModal} setModal={setCardModal} cancel={() => setCardModal(false)} isUpdate={cardModal.update} cardData={cardModal.update && selectedCardDetail} fnEmptyData={fnEmptyData} />}

      {/* Bank Account add and update modal */}
      {(bankModal.add || bankModal.update) && <AddBankDetailsModal modal={bankModal} setModal={setBankModal} cancel={() => setBankModal(false)} isUpdate={bankModal.update} bankData={bankModal.update && selectedBankDetail} fnEmptyData={fnEmptyData} />}

      {deleteModal && <DeleteModal onClickYes={() => fnDeleteCard()} onClickNo={() => setDeleteModal(false)} okTitle={isLoadingDeleteMultiplePayments ? 'Loading' : 'Yes'} />}

    </div>
  )
}

export default PasswordManagerPayments; 