import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { ROUTES } from "./RouteConstants";

function ProtectedRoutes() {
  const loginUser = useSelector((state) => state.UserReducer.loginUser);

  if (!loginUser) {
    return <Navigate to={ROUTES.home} replace />;
  }

  return <Outlet />;
}

export default ProtectedRoutes;
