import React, { useState } from "react";
import { Config } from "../../constants/Index";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../components/localStorage";
import { setSelectedLoginUser } from "../../redux/UserReducer";
import { useDispatch } from "react-redux";
import DZInput from "../../components/shared/inputField/DZInput";
import DZBtn from "../../components/shared/buttons/DZBtn";
import { useLoginUserMutation } from "../../redux/storeApis";
import { Navigate, useNavigate } from "react-router";
import { useUserManager } from "../../lib/customHooks/useUserManager";
import { useSnackBarManager } from "../../lib/customHooks/useSnackBarManager";
import { ROUTES } from "../../reactRoute/RouteConstants";
import DZText from "../../components/shared/text/DZText";
import InputField from "../../components/shared/inputField/InputField";
import Google from "../../icons/productIcon/Google";
import Facebook from "../../icons/productIcon/Facebook";
import Apple from "../../icons/productIcon/Apple";
import GoogleLoginButton from "./GoogleLoginButton";
import FacebookLoginButton from "./FacebookLoginButton";
import { setSelectedUserAccounts } from "../../redux/UserAccountsReducer";
import PP2Service from "../../services";
import useAllAccountData from "../../lib/useAllAccountData";
import useEnterKeySubmit from "../../lib/useEnterKeySubmit";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fnShowSnackBar } = useSnackBarManager();
  const [addLoginUser, { isLoading }] = useLoginUserMutation();
  const { isLoggedInUser } = useUserManager();
  const [validation, setValidation] = useState({ email: false, password: false, });
  const [data, setData] = useState({ email: "", password: "" });
  const [message, setMessage] = useState(null);
  const [loader, setLoader] = useState(false);

  const { handleSelectedEmail } = useAllAccountData();

  const isValidEmail = (email) => {
    return email.includes("@");
  };

  const handleInputChange = (e) => {
    setValidation({ ...validation, email: false });
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleLogin = async (e) => {
    setLoader(true);
    e.preventDefault();

    if (!data.email) {
      setValidation({ ...validation, email: true });
      return;
    } else {
      setValidation({ ...validation, email: false });
    }

    if (!data.password) {
      setValidation({ ...validation, password: true });
      return;
    } else {
      setValidation({ ...validation, password: false });
    }

    try {
      const response = await addLoginUser(data).unwrap();

      if (response?.success) {
        const fetchUserToken = response?.data?.access_token;
        if (fetchUserToken) {
          setLocalStorage(Config.userToken, fetchUserToken);
          setLocalStorage("meId", response?.data?.userId);

          dispatch(setSelectedLoginUser(fetchUserToken));

          const fetchUserAccounts = await PP2Service.fetch({
            url: "user-accounts",
            token: fetchUserToken,
          });
          const userAllAccounts = await fetchUserAccounts?.data?.accounts;
          
          dispatch(setSelectedUserAccounts(userAllAccounts));
          let userAccount = userAllAccounts[0];

          fnShowSnackBar("User successfully logged in!");

          if (userAllAccounts?.length > 0) {
            setTimeout(() => {
              handleSelectedEmail({ emailId : userAccount?.id, email : userAccount?.email, navigateToDashBoard : true});
              setLoader(false);
            }, 2000);
          } else {
            navigate(ROUTES.createAccount);
            setLoader(false);
          }
        } else {
          setMessage("User login Failed");
          console.error("Login failed: Access token not found in response.");
        }
        setLoader(false);
      } else {
        setMessage("User login Failed");
        fnShowSnackBar(response.message || "User login Failed", true);
        setLoader(false);
      }
    } catch (error) {
      setMessage("User login Failed");
      console.error("Login failed:", error);
      fnShowSnackBar("some error occur", true);
      setLoader(false);
    }
  };

  const { handleKeyDown } = useEnterKeySubmit(handleLogin);

  return (
    <div className="flex items-center w-full min-h-full forms-back justify-between px-[6vw] py-[64px] gap-[24px] max-[1004px]:flex-col">
      <div className="flex flex-col gap-[24px] max-w-[460px] max-[1004px]:items-center max-[1004px]:gap-[16px]">
        <DZText
          big
          className={"text-F9F7F7 max-[1004px]:text-center"}
          children={"Jurii"}
        />

        <DZText
          normal
          className={"text-F9F7F7 max-[1004px]:text-center"}
          children={"Get Started Today for Exclusive Services"}
        />

        <DZText
          small
          className={"text-F9F7F7 max-[1004px]:text-center"}
          children={
            "Welcome to our platform! By signing up today, you gain access to a suite of premium services designed to streamline your digital life. Store your important files and memories securely in our cloud storage solution, ensuring they're accessible anytime, anywhere. Safeguard your online accounts with our cutting-edge password manager, offering robust encryption and seamless synchronization across devices. Plus, elevate your communication experience with our personalized email service, empowering you to stay connected with ease. Join us now to unlock a world of convenience and peace of mind."
          }
        />
      </div>

      <div className="flex flex-col gap-[32px] max-[1004px]:p-[20px] p-[32px] w-[540px] max-[626px]:w-full rounded-[24px] bg-[#FFF]">
        <DZText children={"Login"} normal className={"text-112D4E"} />

        <div className="flex flex-col gap-[12px] w-full">
          <InputField
            placeholder={"Email"}
            className={"w-full shadow"}
            value={data?.email}
            type={"email"}
            onChange={handleInputChange}
            name={"email"}
            onKeyDown={handleKeyDown}
          />
          {validation.email && (
            <span style={{ color: "red" }}>⛔ Email is required</span>
          )}
          <InputField
            placeholder={"Password"}
            className={"w-full shadow"}
            value={data?.password}
            type={"password"}
            onChange={handleInputChange}
            name={"password"}
            onKeyDown={handleKeyDown}
          />
        </div>
        {validation.password && (
          <span style={{ color: "red" }}>⛔ password is required</span>
        )}
        <DZBtn
          onClick={handleLogin}
          children={
            isLoading || loader ? "Loading..." : "Login to your account"
          }
          className={"rounded-[10px]"}
          textStyle={"text-[#F7F9F9]"}
          solid
        />
        <div className="flex items-center gap-[12px]">
          <div className="bg-[#DBE2EF80] h-[2px] w-full"></div>
          <DZText children={"Or"} small className={"text-112D4E"} />
          <div className="bg-[#DBE2EF80] h-[2px] w-full"></div>
        </div>
        <div className="flex items-center gap-[12px]">
          <GoogleLoginButton />
        </div>
        <span
          onClick={() => navigate(ROUTES.signup)}
          className="flex items-center justify-center gap-[4px] cursor-pointer m-auto"
        >
          <DZText
            className={"cursor-pointer"}
            children={"Don't have an account?"}
          />
          <DZText
            className={"underline cursor-pointer"}
            children={" Sign Up"}
          />
        </span>
      </div>
    </div>
  );
};

export default Login;
