import React from "react";
import { Link } from "react-router-dom";
import SidebarMainButton from "../shared/sidebar/SidebarMainButton";
const MobileSideBar = ({
  sideBarLinks,
  fnIsActivePath,
  sideBarType,
  clickOnButton,
  showCreatingFolder,
  setShowCreatingFolder
}) => {
  return (
    <div className="shadow-xl fixed flex justify-between items-center gap-[36px] sm:hidden bottom-0 py-[12px] px-[32px] w-full bg-F9F7F7 max-sm:justify-center z-[1]">
      <SidebarMainButton
        sideBarType={sideBarType}
        clickOnButton={clickOnButton}
        showCreatingFolder={showCreatingFolder}
        setShowCreatingFolder={setShowCreatingFolder}
      />
      {sideBarLinks?.map((link) => (
        <Link to={link.path} key={link.path}>
          {link.icon(fnIsActivePath(link.path) ? "#112D4E" : "#3F72AF")}
        </Link>
      ))}
    </div>
  );
};
export default MobileSideBar;
