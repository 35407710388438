import React from "react";
import FacebookLogin from "react-facebook-login";
import { Config } from "../../constants/Index";
import Facebook from "../../icons/productIcon/Facebook";
        import { setLocalStorage } from "../../components/localStorage";
import { setSelectedLoginUser } from "../../redux/UserReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../reactRoute/RouteConstants";

function FacebookLoginButton() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFacebookCallback = async (response) => {
    if (response?.status === "unknown") {
      console.error("Sorry!", "Something went wrong with Facebook Login.");
      return;
    }

    try {
      // Call the API endpoint with the Facebook user data
      const apiResponse = await fetch(
        `${Config.serverApiUrl}login/facebook/callback`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          // body: JSON.stringify({ data: response }),
          body: JSON.stringify({ accessToken: response.accessToken }),
        }
      );

      if (apiResponse.ok) {
        const responseData = await apiResponse.json();
        
        if (responseData.success) {
          // Store the token in local storage
          setLocalStorage(Config.userToken, responseData.access_token);
          // Dispatch action to set user token in Redux store
          dispatch(setSelectedLoginUser(responseData.access_token));
          // Navigate the user to "/allAccounts"
          // navigate(ROUTES.allAccounts);
        } else {
          console.error("Login failed:", responseData.message);
        }
      } else {
        console.error(
          "Failed to log in via Facebook:",
          apiResponse.statusText
        );
      }
    } catch (error) {
      console.error("Failed to log in via Facebook:", error.message);
    }
  };

  return (
    <FacebookLogin
      appId={Config.facebookAppId}
      autoLoad={false}
      fields="name,email,picture"
      callback={handleFacebookCallback}
      render={(renderProps) => <Facebook onClick={renderProps.onClick} />}
    />
  );
}

export default FacebookLoginButton;
