import React from "react";
import DZText from "../../shared/text/DZText";

const TimeDisplay = ({ timestamp }) => {
    
  const formatDate = (timestamp) => {
    if(!timestamp) return
    const date = new Date(timestamp);
    const now = new Date();
    const diffInMs = now - date;
    const diffInHours = diffInMs / (1000 * 60 * 60);
    const diffInDays = diffInHours / 24;
    const diffInYears = now.getFullYear() - date.getFullYear();

    if (diffInYears > 0) {
      // More than a year, display date in "8/19/17" format
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear().toString().substr(-2);
      return `${month}/${day}/${year}`;
    } else if (diffInDays >= 1) {
      // More than 24 hours but less than a year, display date in "Mar 13" format
      const month = date.toLocaleString("default", { month: "short" });
      const day = date.getDate();
      return `${month} ${day}`;
    } else {
      // Less than 24 hours, display time
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const period = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
      return `${formattedHours}:${formattedMinutes} ${period}`;
    }
  };

  return (
    <DZText
      children={formatDate(timestamp) || ""}
      small
      className={"text-nowrap text-112D4E  cursor-pointer"}
    />
  );
};

export default TimeDisplay;
