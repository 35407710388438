import React from "react";
import { useGetReceivedEmailListQuery } from "../../redux/storeApis";
import { useState } from "react";
import { formHelperTextClasses } from "@mui/material";

function useInboxLogic() {
  const { data: emailsData, error, isLoading } = useGetReceivedEmailListQuery();
  const emails = emailsData?.data?.received_emails;
  const [mailDetailStatus, setMailDetailStatus] = useState(false);
  const [mailId, setMailId] = useState(0);
  const [openMailDetail, setOpenMailDetail] = useState(false);
  const handleMailDetail = (id, email) => {
    setMailId(id);
    setOpenMailDetail(emails?.find((e) => e?.id === id));
    setMailDetailStatus(
        mailId == id && mailDetailStatus == true ? false : true
    );
  };
  return {
    emails,
    error,
    isLoading,
  };
}
export default useInboxLogic;
