import React, { useState } from "react";
import { Config } from "../../../constants/Index";
import DZInput from "../../../components/shared/inputField/DZInput";
import DZBtn from "../../../components/shared/buttons/DZBtn";

import { useForgotPasswordMutation } from "../../../redux/storeApis";
import VerifyEmail from "../verifyEmail/VerifyEmail";
import useEnterKeySubmit from "../../../lib/useEnterKeySubmit";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [resetEmailSent, setResetEmailSent] = useState(false);

  const [forgotPassword, { isLoading, isError, error }] =
    useForgotPasswordMutation();

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await forgotPassword(email);
      if (result.error) {
        setMessage(
          result?.error?.data?.message
            ? result?.error?.data?.message
            : "Something went wrong. Please try again laters."
        );
      } else {
        setResetEmailSent(true);
      }
    } catch (error) {
      setMessage("Something went wrong. Please try again later.");
    }
  };
  const {handleKeyDown} = useEnterKeySubmit(handleSubmit);
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      {resetEmailSent ? (
        <VerifyEmail email={email} />
      ) : (
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Forgot Password
          </h2>
          <form className="mt-8" >
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <DZInput
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
            <div className="mt-6">
              <DZBtn
                toolTipText={"Reset Password"}
                textStyle={"text-F9F7F7 cursor-pointer"}
                solid={true}
                toolTipPlacement={"bottom"}
                onClick={handleSubmit}
                className={
                  "cursor-pointer w-full rounded-[10px] py-[12px] px-[24px] "
                }
                children={loading ? "Loading..." : "Reset Password"}
                disabled={loading}
              />
            </div>
            {message && (
              <p className="mt-4 text-center text-red-500">{message}</p>
            )}
          </form>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
