import { useEffect, useRef } from "react"

export function useHandleModalManager({ modal, setModal, closeModal = false }) {

    const modalRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                if(closeModal) { closeModal(); } 
                setModal(false);  
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);
  
        return () => { document.removeEventListener('mousedown', handleOutsideClick); };
    }, [modal]);

    return {
        modalRef
    }
}