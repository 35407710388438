import React from "react";
import { useGoogleLogin } from '@react-oauth/google';
import { Config } from "../../constants/Index";
import { useNavigate } from "react-router-dom";
import { getLocalStorage, setLocalStorage } from "../../components/localStorage";
import { useDispatch } from "react-redux";
import { setSelectedLoginUser } from "../../redux/UserReducer";
import Google from "../../icons/productIcon/Google";
import { useGoogleAuthMutation } from "../../redux/storeApis";
import { useSnackBarManager } from "../../lib/customHooks/useSnackBarManager";
import { ROUTES } from "../../reactRoute/RouteConstants";

const GoogleLoginMail = ({ onSuccess, onFailure }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addGoogleAuth] = useGoogleAuthMutation();

  const { fnShowSnackBar } = useSnackBarManager();


  // const handleGoogleSignIn = async (credentialResponse) => {
  //   const { access_token } = credentialResponse;

  //   try {
  //     const data = await addGoogleAuth({
  //       token: access_token,
     
  //     }).unwrap();

  //     const fetchUserToken = data?.data?.access_token;

  //     if (fetchUserToken) {
  //       setLocalStorage(Config.userToken, fetchUserToken);
  //       dispatch(setSelectedLoginUser(fetchUserToken));
  //       if (onSuccess) onSuccess(fetchUserToken);
  //     }
  //   } catch (error) {
  //     console.error('Google Sign-In was unsuccessful:', error);
  //     if (onFailure) onFailure(error);
  //   }
  // };
  // const handleGoogleSignIn = async (credentialResponse) => {
  //   const { access_token, refresh_token } = credentialResponse;
  //   const userToken = getLocalStorage(Config.userToken);
  //   console.log("refresh_token",credentialResponse);
  //   try {
  //     const response = await fetch(`${Config.serverUrl}api/google/auth`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ${userToken}`
  //       },
  //       body: JSON.stringify({ 
  //         token: access_token,
  //         refresh_token: refresh_token // Send the refresh token to the backend
  //       }),
  //     });
  
  //     if (response.ok) {
  //       const data = await response.json();
  //       const fetchUserToken = data?.data?.access_token;
  
  //       if (fetchUserToken) {
  //         setLocalStorage(Config.userToken, fetchUserToken);
  //         dispatch(setSelectedLoginUser(fetchUserToken));
  //         if (onSuccess) onSuccess(fetchUserToken);
  //       }
  //     } else {
  //       throw new Error('Failed to authenticate with the backend');
  //     }
  //   } catch (error) {
  //     console.error('Google Sign-In was unsuccessful:', error);
  //     if (onFailure) onFailure(error);
  //   }
  // };


  const handleGoogleSignIn = async (codeResponse) => {
    console.log("Code response:", codeResponse);
    const userToken = getLocalStorage(Config.userToken);
    
    try {
      const response = await fetch(`${Config.serverUrl}api/google/auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`
        },
        body: JSON.stringify({ 
          code: codeResponse.code
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log("Server response:", data);
        const fetchUserToken = data?.data?.access_token;
  
        if (fetchUserToken) {
          setLocalStorage(Config.userToken, fetchUserToken);
          dispatch(setSelectedLoginUser(fetchUserToken));
          if (onSuccess) onSuccess(fetchUserToken);
          fnShowSnackBar('Account created successfully!');
          navigate(ROUTES.juriiSettings.accounts);
        }
      } else {
        fnShowSnackBar('Failed to authenticate with the backend');
      }
    } catch (error) {
      console.error('Google Sign-In was unsuccessful:', error);
      if (onFailure) onFailure(error);
      fnShowSnackBar('Account cannot be created, please try again!');
    }
  };
  const handleGoogleSignInFailure = (error) => {
    console.error("Google Sign-In was unsuccessful", error);
    if (onFailure) onFailure(error);
  };

  const login = useGoogleLogin({
    onSuccess: handleGoogleSignIn,
    onError: handleGoogleSignInFailure,
    accessType: 'offline',
    prompt: 'consent',
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
    redirect_uri: "https://jurri.danzeetech.com/inbox"
  });

  return (
    <div style={{width:'100%', margin:'auto'}}> 
      <span className="w-[100%] cursor-pointer border-[1px] py-[13px] flex justify-center items-center rounded-[10px] border-[#DBE2EF]" onClick={login}>  
        <Google />
      </span>
    </div>
  );
};

export default GoogleLoginMail;