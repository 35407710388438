import { useState } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { Config } from "../../constants/Index";
import useGetPathUrl from "../useGetPathUrl";
import { useGetDriveFolderQuery } from "../../redux/juriiDriveApis";

export function useDownloadManager(path) {
  const { pathForFile } = useGetPathUrl();
  const [isDownloading, setIsDownloading] = useState(false);
  const { data: driveFolderData } = useGetDriveFolderQuery(path);

  const handleDownloadFile = async (file) => {
    setIsDownloading(true);
    const fileName = encodeURIComponent(file?.name);
    const fileUrl = `${Config.baseCorsProductUrl}${pathForFile}/${fileName}`;
    const updateUrl = fileUrl.replace(/\/{2,}/g, "/");

    try {
      const response = await fetch(updateUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file?.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDownloadFolder = async (folder) => {
    setIsDownloading(true);
    const zip = new JSZip();
    const folderName = encodeURIComponent(folder?.name);
    const folderPath = `${pathForFile}/${folderName}`;
    const updateFolderPath = folderPath.replace(/\/{2,}/g, "/");

    const getFilesInFolder = async (folderPath) => {
      const data = driveFolderData;
      const files = data?.data?.files;
      const folders = data?.data?.folders;

      if (files?.length > 0) {
        await Promise.all(
          files.map(async (file) => {
            const fileName = encodeURIComponent(file?.name);
            const fileUrl = `${Config.baseCorsProductUrl}${folderPath}/${fileName}`;
            const updateFileUrl = fileUrl.replace(/\/{2,}/g, "/");

            try {
              const response = await fetch(updateFileUrl);
              const blob = await response.blob();
              zip.file(fileName, blob);
            } catch (error) {
              console.error("Error downloading file:", error);
            }
          })
        );
      }

      if (folders?.length > 0) {
        await Promise.all(
          folders.map(async (folder) => {
            const subFolderPath = `${folderPath}/${encodeURIComponent(folder?.name)}`;
            await getFilesInFolder(subFolderPath);
          })
        );
      }
    };

    await getFilesInFolder(updateFolderPath);

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, folderName + ".zip");
      setIsDownloading(false);
    });
  };

  const handleDownload = (item) => {
    if (item?.name?.includes(".")) {
      handleDownloadFile(item);
    } else {
      handleDownloadFolder(item);
    }
  };

  return { handleDownload, isDownloading };
}
