import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { selectIsLoading } from "../../../redux/LoadingReducer";
import { useSelector } from "react-redux";
import DZText from "../text/DZText";
const LoadingBar = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  return (
    <>
      {isLoading && (
        <div
        className={` ${!isLoading ? "translate-x-[100%]" : " translate-x-0"} min-h-[50px] transition duration-300 fixed right-0 bottom-[70px] z-10 px-5 py-3 bg-112D4E shadow-lg rounded-lg min-w-[344px]`}
      >
        <DZText
          children={"Loading..."}
          className={"text-F9F7F7 cursor-text"}
        />
        <span className="absolute h-full top-0 right-0 w-1 bg-DBE2EF">
          <div className="h-full w-full bg-112D4E"></div>
        </span>
      </div>
      )}
    </>
  );
};

export default LoadingBar;
