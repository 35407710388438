import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import DriveCard from '../../components/shared/cards/DriveCard';
import { EmptyLoader } from '../../components/loader/Loaders';
import { useSelector } from 'react-redux';
import { selectIsCardSquare } from '../../redux/CardGridReducer';

const ViewFolders = React.memo(({ filteredFolders, itemTypeModel, isFoldersExist, pathForFile }) => {
  const isCardSquare = useSelector(selectIsCardSquare);
  return (
    <>
      {(itemTypeModel === "All" || itemTypeModel === "Folders") && isFoldersExist ? (
        <div className="flex flex-col gap-[14px]">
          <div className="text-112D4E font-[500] text-[22px]">Drive Folders</div>
          <div className={`${isCardSquare ? "grid max-xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 max-[420px]:grid-cols-1 grid-cols-6 max-2xl:grid-cols-5 " : "flex flex-col"} gap-[24px]`}>
            {filteredFolders?.map((folder, index) => (
              <Droppable droppableId={`${pathForFile}/${folder?.name}`} key={folder?.id}>
                {(provided) => (
                  <span {...provided.droppableProps} ref={provided.innerRef}>
                    <DriveCard cardType="folder" pathName={pathForFile} folder={folder} index={index} />
                    {provided.placeholder}
                  </span>
                )}
              </Droppable>
            ))}
          </div> 
        </div>
      ) : (
        itemTypeModel === "Folders" && <EmptyLoader />
      )}
    </>
  );
});

export default ViewFolders;
