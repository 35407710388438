import React, { useState } from "react";
import DZText from "../text/DZText";
import InputField from "../inputField/InputField";
import DZBtn from "../buttons/DZBtn";
import DZTextArea from "../inputField/DZTextArea";
import { useHandleModalManager } from "../../../lib/customHooks/useHandleModalManager";
import {
  useCreatePaymentMutation,
  useUpdatePaymentMutation,
} from "../../../redux/passwordManagerApis";
import { useSnackBarManager } from "../../../lib/customHooks/useSnackBarManager";
import useEnterKeySubmit from "../../../lib/useEnterKeySubmit";
import DZModal from "./DZModal";

const AddBankDetailsModal = ({
  ok,
  cancel,
  title = "Add a bank account",
  modal,
  setModal,
  isUpdate = false,
  bankData,
  fnEmptyData,
}) => {
  const [createPayment, { isLoading: isLoadingCreatePayment }] =
    useCreatePaymentMutation();
  const [updatePayment, { isLoading: isLoadingUpdatePayment }] =
    useUpdatePaymentMutation();

  // const { modalRef } = useHandleModalManager({ modal, setModal });
  const { fnShowSnackBar } = useSnackBarManager();

  const [inputData, setInputData] = useState({
    bankName: bankData?.b_name ?? "",
    accountHolderName: bankData?.name ?? "",
    accountNumber: bankData?.card_number ?? "",
    country: bankData?.country ?? "",
    note: bankData?.note ?? "",
  });

  const inputFieldsData = [
    { name: "bankName", value: inputData?.bankName, placeholder: "Bank name*" },
    {
      name: "accountHolderName",
      value: inputData?.accountHolderName,
      placeholder: "Add account holder's name*",
    },
    {
      name: "accountNumber",
      value: inputData?.accountNumber,
      placeholder: "Add account Number*",
    },
    { name: "country", value: inputData?.country, placeholder: "Country*" },
  ];

  const fnOnChange = (event) => {
    const { name, value } = event.target;
    setInputData((pre) => ({ ...pre, [name]: value }));
  };

  const fnAddBank = () => {
    const data = {
      b_name: inputData.bankName,
      name: inputData.accountHolderName,
      card_number: inputData.accountNumber,
      country: inputData?.country,
      note: inputData.note,
      type: "bank",
    };
    if (
      inputData.bankName &&
      inputData.accountHolderName &&
      inputData.accountNumber &&
      inputData?.country
    ) {
      createPayment(data)
        .unwrap()
        .then((payload) => {
          if (payload.success) {
            setModal(false);
            fnShowSnackBar("Bank Added!");
            setInputData(null);
          }
        })
        .catch((error) => {
          fnShowSnackBar("Something went wrong, please try agian!");
        });
    } else {
      fnShowSnackBar("Please fill the required fields!");
    }
  };

  const fnUpdateBank = () => {
    const data = {
      id: bankData?.id,
      b_name: inputData.bankName,
      name: inputData.accountHolderName,
      card_number: inputData.accountNumber,
      country: inputData?.country,
      note: inputData.note,
      type: "bank",
    };

    if (
      inputData.bankName &&
      inputData.accountHolderName &&
      inputData.accountNumber &&
      inputData?.country
    ) {
      updatePayment(data)
        .unwrap()
        .then((payload) => {
          if (payload.success) {
            setModal(false);
            fnShowSnackBar("Bank Updated!");
            setInputData(null);
          }
        })
        .catch((error) => {
          fnShowSnackBar("Something went wrong, please try agian!");
        });
    } else {
      fnShowSnackBar("Please fill the required fields!");
    }
  };
  const { handleKeyDown } = useEnterKeySubmit(() => {
    isUpdate ? fnUpdateBank() : fnAddBank();
  });
  return (
    <DZModal>
      <DZText
        normal
        children={isUpdate ? "Update a bank account" : title}
        className={"text-112D4E"}
      />

      {inputFieldsData?.map((input, i) => (
        <InputField
          key={i}
          name={input?.name}
          value={input?.value}
          placeholder={input?.placeholder}
          className={styles.input}
          onChange={fnOnChange}
          onKeyDown={handleKeyDown}
        />
      ))}

      <DZTextArea
        name={"note"}
        value={inputData?.note}
        onChange={fnOnChange}
        className={styles.input}
        onKeyDown={handleKeyDown}
      />

      <div className="flex items-center gap-[14px]">
        <DZBtn
          onClick={() => {
            isUpdate ? fnUpdateBank() : fnAddBank();
          }}
          solid
          className={styles.addBtn}
        >
          {isUpdate
            ? isLoadingUpdatePayment
              ? "Loading..."
              : "Update"
            : isLoadingCreatePayment
            ? "Loading..."
            : "Add"}
        </DZBtn>
        <DZBtn onClick={cancel} className={styles.cancelBtn}>
          {"Cancel"}
        </DZBtn>
      </div>
    </DZModal>
  );
};
const styles = {
  input: "w-full placeholder:text-3F72AF",
  addBtn: "text-white rounded-[10px] cursor-pointer min-w-[104px]",
  cancelBtn: "rounded-[10px] text-112D4E cursor-pointer bg-white shadow",
};

export default AddBankDetailsModal;
