import React, { useEffect } from "react";
import { useGetFavouritesQuery } from "../../redux/storeApis";
import useMailDetailLogic from "../../lib/customHooks/useMailDetailLogic";

import MailDetail from "../../components/shared/mailDetail/MailDetail";
import DropDownView from "../../components/shared/dropDowns/DropDownView";
import MailCard from "../../components/shared/mailCard/MailCard";
import OperationBar from "../../components/opreationBar/OperationBar";
const Starred = () => {
  const { data: favorites, isLoading, isError } = useGetFavouritesQuery();
  const { mailDetailStatus, mailId, handleMailDetail, openMailDetail } =
    useMailDetailLogic();
  const emails = favorites?.data?.favourite_emails?.favourites;
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error occurred while fetching favorites.</div>;
  }
  return (
      <div className="flex items-start w-full gap-[16px]">
        <div className="flex flex-col items-center w-full gap-[16px] overflow-y-scroll  h-[84svh] max-sm:h-[76vh]">
          {emails &&
            emails?.map((email) => (
              <MailCard
                mailId={mailId}
                handleMailDetail={handleMailDetail}
                key={email?.id}
                email={email?.email}
                emails={emails}
              />
            ))}
        </div>
        {mailDetailStatus ? <MailDetail openMailDetail={openMailDetail} /> : ""}
      </div>
  );
};
export default Starred;
