import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "UserReducer",
  initialState: {
    loginUser: null,
  },
  reducers: {
    setSelectedLoginUser: (state, action) => {
      state.loginUser = action.payload;
    },
  },
});

export const { setSelectedLoginUser } = slice.actions;

export const selectedLoginUser = (state) => state.UserReducer.loginUser;

export default slice.reducer;
