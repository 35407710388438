import React from "react";

const PasswordIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      className={className}
    >
      <path
        d="M13.5 7.75309C13.0624 6.93778 12.2047 6.36831 11.2139 6.32447C10.1078 6.27553 8.98413 6.25 7.74673 6.25C6.50934 6.25 5.38568 6.27553 4.27953 6.32447C2.96496 6.38264 1.88471 7.36606 1.70912 8.62132C1.59452 9.44055 1.5 10.2801 1.5 11.135C1.5 11.99 1.59452 12.8295 1.70912 13.6488C1.88471 14.904 2.96496 15.8874 4.27953 15.9456C4.81509 15.9693 5.44531 15.9875 6 16"
        stroke="#112D4E"
        stroke-width="1.2"
        stroke-linecap="round"
      />
      <path
        d="M4.5 6.25V4.375C4.5 2.51104 6.01104 1 7.875 1C9.73896 1 11.25 2.51104 11.25 4.375V6.25"
        stroke="#112D4E"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.9034 10.8284L15.4831 11.2565L15.4831 11.2565L15.9034 10.8284ZM16.0796 11.8423C16.3161 12.0745 16.696 12.071 16.9281 11.8345C17.1603 11.5981 17.1568 11.2182 16.9204 10.9861L16.0796 11.8423ZM13.5171 10.8284L13.0968 10.4003L13.0968 10.4003L13.5171 10.8284ZM13.6933 13.0138C13.9298 13.246 14.3097 13.2425 14.5418 13.0061C14.774 12.7696 14.7705 12.3898 14.5341 12.1576L13.6933 13.0138ZM10.7101 15.0866C10.2846 15.5045 9.59015 15.5045 9.16459 15.0866L8.32385 15.9429C9.2162 16.819 10.6585 16.819 11.5509 15.9429L10.7101 15.0866ZM9.16459 15.0866C8.74514 14.6748 8.74514 14.0116 9.16459 13.5998L8.32385 12.7435C7.42538 13.6257 7.42538 15.0607 8.32385 15.9429L9.16459 15.0866ZM9.16459 13.5998C9.59015 13.1819 10.2846 13.1819 10.7101 13.5998L11.5509 12.7435C10.6585 11.8673 9.2162 11.8673 8.32385 12.7435L9.16459 13.5998ZM10.7101 13.5998C11.1296 14.0116 11.1296 14.6748 10.7101 15.0866L11.5509 15.9429C12.4494 15.0607 12.4494 13.6257 11.5509 12.7435L10.7101 13.5998ZM15.4831 11.2565L16.0796 11.8423L16.9204 10.9861L16.3238 10.4003L15.4831 11.2565ZM11.5509 13.5998L13.3409 11.8423L12.5002 10.986L10.7102 12.7435L11.5509 13.5998ZM13.3409 11.8423L13.9375 11.2565L13.0968 10.4003L12.5002 10.9861L13.3409 11.8423ZM12.5002 11.8423L13.6933 13.0138L14.5341 12.1576L13.3409 10.9861L12.5002 11.8423ZM16.3238 10.4003C16.0546 10.136 15.8107 9.8947 15.5867 9.72691C15.3485 9.54842 15.0656 9.4 14.7103 9.4V10.6C14.7139 10.6 14.72 10.5998 14.7377 10.607C14.76 10.6162 14.8006 10.6374 14.8672 10.6873C15.0119 10.7956 15.1898 10.9686 15.4831 11.2565L16.3238 10.4003ZM13.9375 11.2565C14.2308 10.9686 14.4087 10.7956 14.5533 10.6873C14.6199 10.6374 14.6606 10.6162 14.6829 10.607C14.7006 10.5998 14.7067 10.6 14.7103 10.6V9.4C14.355 9.4 14.0721 9.54842 13.8338 9.72691C13.6099 9.8947 13.366 10.136 13.0968 10.4003L13.9375 11.2565Z"
        fill="#112D4E"
      />
    </svg>
  );
};

export default PasswordIcon;
